import { Box, Button, Typography } from '@mui/material';
import React from 'react'

import Consultation from '../Consultation';

const LearnMore = () => {
  const [open, setOpen] = React.useState(false)

  return (
    <Box
      sx={{
        backgroundColor: 'black.dark',
        padding: {
          lg: '160px',
          sm: '160px 30px',
          xs: '100px 30px'
        }
      }}
    >
      <Typography
        variant='h4'
        component={'h4'}
      >
        Let’s talk about how RWA can amplify your investing situation
      </Typography>
      <Box
        display={'flex'}
        flexDirection={{xs: 'column', sm:'row'}}
        paddingTop={'60px'}
        gap={2.5}
      >
        <Button
          variant='outlined'
          sx={{
            borderRadius: '20px',
            padding: '20px 50px'
          }}
          onClick={() => setOpen(true)}
        >
          Request A Consultation
        </Button>
      </Box>
      {
        open ? (
          <Consultation
            open={open}
            handleClose={() => setOpen(false)}
          />
        ) : null
      }
    </Box>
  )
}

export default LearnMore;