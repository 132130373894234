import React from "react";

const CardRightIcon = ({
  width = 212,
  height = 320,
  color = 'white'
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 212 320"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fill-rule="evenodd" clip-rule="evenodd" d="M186.543 -67.7721L186.293 -68.2051L185.86 -67.9551L123.439 -31.9162L123.006 -31.6662L123.256 -31.2332L231.14 155.627L195.133 176.416C180.545 184.839 175.546 203.492 183.969 218.08L277.103 379.393L277.353 379.826L277.786 379.576L340.207 343.537L340.64 343.287L340.39 342.854L232.506 155.993L269.525 134.62C283.557 126.519 288.362 108.584 280.261 94.5527L186.543 -67.7721ZM124.372 -31.3002L185.927 -66.8391L279.395 95.0527C287.22 108.606 282.578 125.929 269.025 133.754L232.006 155.127L124.372 -31.3002ZM63.6173 -51.9498L63.3673 -52.3828L62.9343 -52.1328L0.513092 -16.0939L0.0800781 -15.8439L0.330079 -15.4109L108.214 171.45L72.2068 192.239C57.6188 200.661 52.6206 219.314 61.043 233.902L154.177 395.215L154.427 395.648L154.86 395.398L217.281 359.359L217.714 359.109L217.464 358.676L109.58 171.815L146.6 150.442C160.631 142.341 165.436 124.406 157.335 110.375L63.6173 -51.9498ZM1.4461 -15.4779L63.0013 -51.0168L156.469 110.875C164.294 124.428 159.652 141.751 146.1 149.576L109.08 170.949L1.4461 -15.4779ZM72.7068 193.105L108.714 172.316L216.348 358.743L154.793 394.282L61.909 233.402C53.7628 219.293 58.5971 201.251 72.7068 193.105ZM231.64 156.493L195.633 177.282C181.523 185.429 176.689 203.47 184.835 217.58L277.719 378.46L339.274 342.921L231.64 156.493Z" fill={color}/>
    </svg>
  )
}

export default CardRightIcon;
