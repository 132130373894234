import Typography from "@mui/material/Typography";

import { Stack, Grid } from "@mui/material";

const Members = ({ data }) => {
  return data.map(({ name, bio }, idx) => (
    <Grid item xs={12} md={6} lg={4} key={idx}>
      <Stack justifyContent="space-between" spacing={3} width="75%">
        <Typography fontSize="17.5px" color={"black"}>
          {name}&nbsp; &nbsp;
        </Typography>
        <Typography fontSize="17.5px" color={"black"}>{bio}</Typography>
      </Stack>
    </Grid>
  ));
};

export default Members;
