import React from 'react';
import { Dialog, DialogTitle, DialogContent, Button, DialogActions, Stack, useTheme, useMediaQuery  } from '@mui/material';
import { Link } from 'react-router-dom';

const Consultation = ({
  open=false,
  handleClose,
  fullScreen = false
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  if (isMobile) fullScreen = true

  const buttons = [
    {
      name: 'Schedule A Consultation',
      link: 'https://calendly.com/d/2v7-ssw-fg5/learn-more-about-tomei-on-zoom',
      target: '_blank'
    },
    {
      name: 'FAQs for Institutions',
      link: '/faq_institutions',
      target: '_self'
    },
    {
      name: 'FAQs for Private Equity',
      link: '/faq_individuals',
      target: '_self'
    }
  ]

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen={fullScreen}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: 'black.main',
            opacity: '0.8 !important'
          }
        }
      }}
      sx={{
        '& .MuiPaper-root': {
          backgroundColor: 'black.dark',
          color: 'white',
          borderRadius: {
            xs: 0,
            sm: '30px'
          },
          border: '1px solid white'
        },
        '& .MuiDialogActions-root': {
          margin: '16px'
        },
        '& .MuiDialogContent-root': {
          flexGrow: {
            xs: 'initial'
          }
        },
        '& .MuiPaper-elevation': {
          marginTop: {
            xs: '120px',
            sm: 0
          }
        },
        '& .MuiPaper-elevation > .MuiTypography-root': {
          marginTop: {
            xs: '100px',
            sm: 0
          }
        }
      }}
    >
      <DialogTitle>Get Started</DialogTitle>
      <DialogContent>
        <Stack
          sx={{
            width: {
              xs: '100%',
              sm: '400px'
            },
            padding: '40px 0px 20px 0'
          }}
          spacing={2}
        >
          {
            buttons?.map((button, index) => {
              return (
                <Button
                  to={button.link}
                  target={button.target}
                  component={Link}
                  variant='outlined'
                  sx={{
                    borderRadius: '20px',
                    padding: '20px 50px'
                  }}
                  key={index}
                  onClick={handleClose}
                >
                  {button.name}
                </Button>
              )
            })
          }
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

export default Consultation;
