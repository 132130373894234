import React from 'react'
import { Box, Typography, Link as MUILink, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';

import CircleRightArrow from '../SVG/CircleRightArrow';
import { ReactComponent as Avalanche } from '../../assets/avalanche.svg'
import { ReactComponent as Brinks } from '../../assets/brinks.svg'
import { ReactComponent as SIG } from '../../assets/sig_quantitive.svg'

const StrategicPartners = () => {
  const items = [
    {
      logo: <SIG />,
      name: "Susquehanna",
      description: "Institutional global market maker and quantitative trading"
    },
    {
      logo: <Brinks/>,
      name: "Brinks",
      description: "Data and information system integration"
    },
    {
      logo: <Avalanche/>,
      name: "Avalanche",
      description: "Secured by the Avalanche Cloud"
    }
  ]

  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: 'white',
        padding: {
          xs: "80px 20px 40px 20px",
          md: '160px 20px 80px 20px',
          lg: '160px 160px 80px 160px'
        }
      }}
    >
      <Typography component={'h3'} variant='h3' sx={{maxWidth: "793px", color: "black.main", fontWeight: 400}}>Our end to end solution delivers compliance and flexibility through our strategic partners</Typography>
      
      <Box
        sx={{
          display: "flex",
          flexDirection: {
            xs: "column",
            sm: "row"
          },
          gap: {
            xs: "30px",
            sm: "73px"
          },
          margin: {
            xs: "30px 0",
            sm: '30px 0 30px 0'
          }
        }}
      >
        {
          items?.map((item, index) => {
            return (
              <Box key={index}
                sx={{
                  padding: "20px",
                  borderRadius: "30px",
                  border: "1px solid #FBA850",
                  width: "100%",
                  gap: "10px",
                  color: "black.main",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  textAlign: "center"
                }}
              >
                {item.logo}
                <Typography variant='p1' component={'p'} sx={{fontWeight: 600}}>{item.name}</Typography>
                <Typography variant='h4' component={'h4'} sx={{fontWeight: 400}}>{item.description}</Typography>
              </Box>
            )
          })
        }
      </Box>
        
      {/* <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center"
        }}
      >
        <MUILink
            component={Link}
            to="/about-rwa"
            underline="none"
            sx={{
              display: "flex",
              gap: "10px",
              borderRadius: '20px',
              padding: '30px',
              color: "white",
              backgroundColor: "black.main",
              transition: theme.transitions.create(['color', 'background-color']),
              "&:hover" : {
                backgroundColor: "#FFE1D7",
                color: "black.main",
              }
            }}
          >
            Learn more about Jiritsu RWA tokenization
            <CircleRightArrow />
        </MUILink>
      </Box> */}
    </Box>
  )
}

export default StrategicPartners;
