export const items = [
  {
    question: "Beneifits of automation",
    answer: "To clarify the benefits of using a Zero-Knowledge Multi-Party Computation (Zk-MPC) oracle versus self-reporting, several key factors need consideration."
  },
  {
    question: "Integration Depth and Data Retrieval Methods",
    answer: `Zk-MPC oracles can either use a "push" or "pull" mechanism to gather data. In a "push" system, the data provider sends information directly to the oracle, which can introduce risks if the provider’s integrity or security is compromised. On the other hand, a "pull" system allows the oracle to fetch data directly from integrated systems through APIs, such as supply chain software or banking information systems. This method typically offers deeper integration and reduces risks since compromising the data would require breaching these robust systems.`,
  },
  {
    question: "Verified Computations",
    answer: "Zk-MPC oracles enhance reliability by not only aggregating data from multiple parties but also verifying the computations involved, such as valuation models. This capability ensures that the data and the processes used to compute values are both secure and accurate.",
  },
  {
    question: "IoT Integration",
    answer: "In scenarios involving IoT devices, such as cameras monitoring inventory, Zk-MPC oracles can securely aggregate and verify data from these devices. This reduces the likelihood of data tampering and enhances the trustworthiness of the data reported.",
  },
  {
    question: "Data Secrecy and Security",
    answer: "Zk-MPC enhances data security by ensuring that while data is aggregated and used to make decisions, the individual inputs remain confidential. This is particularly advantageous in maintaining the secrecy of sensitive information.",
  },
  {
    question: "Transparency and Auditability",
    answer: "In addition to the secrecy and security features, Zk-MPC also offers transparency in the data handling process. The chain of custody for data can be audited, which doesn’t necessarily increase trust beyond the existing systems but allows end users to assess and decide if the level of trust provided is sufficient for their needs.",
  },
  {
    question: "Blockchain Integration",
    answer: "By integrating Zk-MPC data onto blockchain platforms, it enables on-chain solutions, such as decentralized finance (DeFi), to utilize this verified and secure data. This linkage not only broadens the use cases for blockchain applications but also enhances the functionality and trustworthiness of DeFi platforms by providing them with access to secure, real-world data.",
  },
  {
    question: "Example HXRO binary option",
    answer: "An example of the power of the use of Zk-MPC in parimutuel systems, liquidity providers are key because they supply the capital needed to balance bets. The secrecy around their strategies is crucial as these providers are in competition. Zk-MPC ensures that while the effects of their contributions are acknowledged in the system, the specific strategies and positions they hold remain confidential. This protects their competitive advantage and encourages participation by assuring providers that their proprietary methods are secure from competitors.",
  },
];