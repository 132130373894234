import React from 'react';

import Hero from '../components/Faq/hero';
import FAQs from '../components/Faq/individual';
import LearnMore from '../components/Faq/learn-more';
import ScrollToTop from '../components/Scroll/scrollTop';

const FAQIndividuals = () => {
  return (
    <>
      <ScrollToTop/>
      <Hero isIndividual={true}/>
      <FAQs />
      <LearnMore />
    </>
  )
}

export default FAQIndividuals;