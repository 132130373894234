import React from "react";

const ShortLogo = ({
  width = 202,
  height = 60,
  color = 'white'
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 202 60"
      xmlns="http://www.w3.org/2000/svg"
    >
    <path d="M0 41.6606H28.3511C41.8126 41.6606 52.0003 30.8729 52.0003 17.4114V0.00195312H71.9997V17.5514C71.9997 27.957 68.1918 37.9727 61.28 45.7505C53.2323 54.8062 41.2667 60 29.1511 60H0V41.6606Z" fill={color}/>
    <path d="M175.994 18.3414H147.643C134.181 18.3414 123.993 29.129 123.993 42.5906V60H103.994V42.4506C103.994 32.0449 107.802 22.0292 114.714 14.2515C122.766 5.19578 134.731 0.00195312 146.845 0.00195312H175.994V18.3414Z" fill={color}/>
    <path d="M201.994 60V0.00195312L181.995 0.00195313V60H201.994Z" fill={color}/>
    <path d="M77.998 0V59.998H97.9974V0H77.998Z" fill={color}/>
    </svg>
  )
}

export default ShortLogo;
