import React from 'react'
import { Box, Typography, Grid, Divider } from '@mui/material';
import { useTheme, useMediaQuery } from '@mui/material';

import { ReactComponent as Avalanche } from '../../assets/investors/Avalanche.svg'
import { ReactComponent as KH22 } from '../../assets/investors/KH22.svg'
import { ReactComponent as Age } from '../../assets/investors/age.svg'
import { ReactComponent as Saxon } from '../../assets/investors/saxon.svg'
import { ReactComponent as ShimaCapital } from '../../assets/investors/shima_capital.svg'
import { ReactComponent as PolymorephicCapital } from '../../assets/investors/polymorephic_capital.svg'
import { ReactComponent as EverNew } from '../../assets/investors/evernew_capital.svg'
import { ReactComponent as Provenance } from '../../assets/investors/provenance.svg'
import { ReactComponent as Sig } from '../../assets/investors/sig.svg'
import { ReactComponent as GumiCryptos } from '../../assets/investors/gumi_cryptos.svg'
import { ReactComponent as RepublicCapital } from '../../assets/investors/republic_capital.svg'
import { ReactComponent as Comma3Ventures } from '../../assets/investors/comma3_ventures.svg'
import { ReactComponent as Eterna } from '../../assets/investors/eterna.svg'
import { ReactComponent as Serafund } from '../../assets/investors/serafund.svg'

const Investors = () => {
  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.down("md"))

  const items = [
    {
      name: "Sig",
      logo: <Sig />,
      link: "https://sig.com/"
    },
    {
      name: "ShimaCapital",
      logo: <ShimaCapital />,
      link: "https://shima.capital/"
    },
    {
      name: "Avalanche",
      logo: <Avalanche />,
      link: "https://www.avax.network//"
    },
    {
      name: "Saxon",
      logo: <Saxon />,
      link: "https://saxon.xyz/"
    },
    {
      name: "Age",
      logo: <Age/>,
      link: "https://age.fund/"
    },
    {
      name: "PolymorephicCapital",
      logo: <PolymorephicCapital/>,
      link: "https://polymorphic.capital/"
    },
    {
      name: "EverNew",
      logo: <EverNew />,
      link: "https://evernewcap.com/"
    },
    {
      name: "GumiCryptos",
      logo: <GumiCryptos/>,
      link: "https://www.gumi-cryptos.com/"
    },
    {
      name: "RepublicCapital",
      logo: <RepublicCapital/>,
      link: "https://republic.com/"
    },
    {
      name: "Comma3Ventures",
      logo: <Comma3Ventures />,
      link: "https://comma3.co/"
    },
    {
      name: "Eterna",
      logo: <Eterna />,
      link: "https://www.eternacapital.com/"
    },
    {
      name: "Serafund",
      logo: <Serafund />,
      link: "https://www.serafund.co/"
    },
    {
      name: "kittyhawk",
      logo: <KH22 />,
      link: "https://www.kittyhawk.vc/"
    },
  ]

  return (
    <Box
      sx={{
        backgroundColor: "black.main"
      }}
    >
      <Box
        sx={{
          backgroundColor: 'white',
          padding: {
            xs: '30px',
            lg: '60px 160px'
          },
          borderRadius: "40px 40px 0px 0px"
        }}
      >
        <Typography
          variant='h3'
          component={'h3'}
          sx={{
            marginBottom: {
              xs: '30px',
              sm: '60px'
            },
            color: "black.main",
            fontWeight: 300,
            textAlign: "center"
          }}
        >
          Investors
        </Typography>
        <Grid
          container
          rowSpacing={10}
          sx={{
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {
            items?.map((item) => {
              return (
                <Grid
                  key={item.name}
                  item xs={6}
                  sm={6}
                  md={4}
                  lg={3}
                  sx={{

                  }}
                >
                  <a href={item.link} target="_blank" rel="noreferrer"
                    style={{
                      display: "flex",
                      justifyContent: "center"
                    }}
                  >
                    {item.logo}
                  </a>
                </Grid>
              )
            })
          }

        </Grid>
      </Box>
    </Box>
  )
}

export default Investors;
