import React from 'react'

import Hero from '../components/Roadmap/hero';
import FAQs from '../components/Roadmap/faqs';
import ScrollToTop from '../components/Scroll/scrollTop';


const Roadmap = () => {
  return (
    <>
      <ScrollToTop/>
      <Hero/>
      <FAQs/>
    </>
  )
}

export default Roadmap;