import { Typography, Stack } from "@mui/material";

const WhyJiri = () => {
  return (
    <Stack sx={{ color: "#0A0A0A" }}  justifyContent="flex-end" spacing="3.75rem">
      <Typography fontSize="16px" fontWeight="600" letterSpacing="0.1em">
      Why Jiri
      </Typography>
      <Typography fontSize="17.5px" color="black">
        Each node runs an operating system powered by computational engines and a workflow manager that deliver Proof of Workflow (PoWF).<br /><br />
        UVC is live with over 3.5 million proof and growing daily.
      </Typography>
      <Typography fontSize="30px">
        {`Jiritsu allows you to orchestrate and verify any computational workflow, integrating any combination of L1, L2 and Middleware.`}
      </Typography>
    </Stack>
  );
};

export default WhyJiri;
