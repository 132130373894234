import { Typography, Grid, Box } from "@mui/material";

const WhyJiri = () => {
  return (
    <Box
      sx={{
        color: "#0A0A0A"
      }}
    >
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        width={1}
        sx={{
          padding: {
            xs: "82px 30px",
            md: "82px 160px",
          },
          background: "white",
          borderRadius: "60px 60px 0 0",
          "& > :first-of-type": {
            paddingBottom: "30px",
          },
          "& > :last-child": {
            paddingBottom: 6,
          },
        }}
      >
        <Grid item xs={12} alignSelf="flex-start">
          <Typography fontSize="16px" fontWeight="600">
          Why Jiri
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography
            sx={{ whiteSpace: "pre-line", fontSize: "17.5px", width: 0.75, color: "black" }}
          >
            {`Each node runs an operating system powered by computational engines and a workflow manager that deliver Proof of Workflow (PoWF).\n
                UVC is live with over 3.5 million proof and growing daily.`}
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: "30px",
              lineHeight: "39px",
              width: 1,
            }}
          >
            {`Jiritsu allows you to orchestrate and verify any computational workflow, integrating any combination of L1, L2 and Middleware.`}
          </Typography>
        </Grid>
        <Grid item xs={2} />
      </Grid>
    </Box>
  );
};

export default WhyJiri;
