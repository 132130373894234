import React from 'react'

import Hero from '../components/Homepage/hero';
import Investors from '../components/Homepage/investors';
import ScrollToTop from '../components/Scroll/scrollTop';
import StrategicPartners from '../components/Homepage/strategic_partners';
import Technologies from '../components/Homepage/technologies';
import ZKMPC from '../components/Homepage/zk';

const Home = () => {
  return (
    <>
      <ScrollToTop/>
      <Hero/>
      <StrategicPartners />
      <ZKMPC />
      <Technologies />
      <Investors />
    </>
  )
}

export default Home;