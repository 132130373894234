import React from 'react';
import { Typography } from '@mui/material';

const Hero = () => {
  return (
    <Typography
      variant="h2"
      component="h1"
      sx={{
      padding: {
        xs: '110px 30px 172px 30px',
        lg : '220px 160px 186px 160px',
      },
      bgcolor: 'black.main',
      fontSize: {
        xs: '50px',
        md: '80px'
      }
      }}
    >
      Case <br/> Studies
    </Typography>
  )
}

export default Hero;