import * as React from "react";
import { Typography, Stack, Divider, Box } from "@mui/material";
import GitbookButton from "./GitbookButton";

import TechListItem from "./TechListItem";
import data from "../../../../assets/technology/techListData.json";
import { ReactComponent as JiriMPCTag } from "../../../../assets/technology/Diagram/Jiri MPC Tag.svg";
import { ReactComponent as MPCNode3 } from "../../../../assets/technology/Diagram/MPC Node 3.svg";

const { content } = data;

const TechnologyList = React.forwardRef((props, ref) => {
  return (
    <Stack sx={{ color: "#0A0A0A" }} justifyContent="flex-end" spacing="3.75rem" width={1} ref={ref}>
        <Typography fontSize="16px" fontWeight="600" marginBottom={'40px'} color={'black'}
          sx={{
            paddingTop: "30px",
            borderTop: "1px solid #3E54FF"
          }}
        >
          Our Technologies
        </Typography>
      <Typography sx={{ whiteSpace: "pre-line", fontSize: "20px", color: "black" }}>
        {`Jiritsu relies on mathematics to ensure both optimal and secure performance.\n
        Our proprietary and patent pending technology includes a composable nodes that can be easy configured to support any orchestration. The nodes are decentralized to ensure fidelity and uptime.`}
      </Typography>
      <GitbookButton />
      <Box
        sx={{ 
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "40px",
          padding: "30px 15px",
          margin: "80px 60px",
          width: "100%",
          borderRadius: "30px",
          backgroundColor: "#FAFAFA"
        }}
      >
        <JiriMPCTag style={{ maxWidth: "100%", height: "auto" }} />
        <MPCNode3 style={{ maxWidth: "100%", height: "auto" }} />
      </Box>
      <Stack pt="3.75rem">
        {content.map((obj, idx) => (
          <Box key={idx}>
            <Divider sx={{ width: "108%" }} />
            <TechListItem {...obj} />
          </Box>
        ))}
      </Stack>
    </Stack>
  );
});

export default TechnologyList;
