import React from 'react'

import Hero from '../components/ZKMPCVerifier/hero';
import FAQs from '../components/ZKMPCVerifier/faq';
import ScrollToTop from '../components/Scroll/scrollTop';


const FAQ = () => {
  return (
    <>
     <ScrollToTop/>
      <Hero/>
      <FAQs/>
    </>
  )
}

export default FAQ;