import React from 'react'
import { Box, Typography, Grid } from '@mui/material';
import { Link } from 'react-router-dom';

import CircleRightArrow from '../SVG/CircleRightArrow';
import ZKLayer from '../../assets/zk_layer.svg'
// import { ReactComponent as ZKLayer } from '../../assets/zk_layer.svg'
import { ReactComponent as RWA } from '../../assets/jiri_rwa.svg'
import { ReactComponent as IconLink } from '../../assets/icon_link.svg'

const ZKMPC = () => {


  return (
    <Box
      sx={{
        backgroundColor: 'black.main',
        padding: {
          xs: "20px",
          lg: '200px 160px 120px 160px'
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: {
            xs: "column",
            md: "row"
          }
        }}
      >
        <Box
        sx={{
          // backgroundImage : `url(${ZKLayer})`,
          // backgroundRepeat: 'no-repeat',
          // backgroundSize: 'cover',
          // backgroundPosition: 'center',
          width: {
            xs: "100%",
            lg: "580px"
          },
        }}
      >
        {/* <ZKLayer style={{width: "100%", height: "auto"}} /> */}
        <Box component="img" width={"100%"} src={ZKLayer}
          sx={{
            width: {
              xs: "100%",
              lg: "580px"
            },
            display: "flex",
            height: "100%",
            objectFit: "cover",
            borderRadius: {
              xs: "30px 30px 0 0",
              md: "30px 0 0 30px"
            }
          }}
        />
      </Box>
      <Box
        sx={{
          borderRadius: {
            xs: "0 0 30px 30px",
            md: "0 30px 30px 0"
          },
          padding: {
            xs: "30px",
            sm: "90px 100px",
            backgroundColor: "white",
            color: "black.main"
          }
        }}
      >
        <Typography component={'h3'} variant='h3' sx={{marginBottom: "30px", fontWeight: 300, color: "black.main"}}>
          ZK MPC translates off-chain data into verifiable proofs on Jiritsu's Layer 1 for RWA
        </Typography>
        <Typography component={'p'} variant='p1' sx={{color: "black.main"}}>
        On-chain verification of RWA ensures tokenization you can trust.
        </Typography>
      </Box>
          
      </Box>

        
    </Box>
  )
}

export default ZKMPC;
