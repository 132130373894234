import { Box, Typography, Divider, Link as MUILink } from '@mui/material';
import React from 'react'
import { Link } from 'react-router-dom';

import { productsData } from '../../pages/prospectus';
import MediumWhiteIcon from '../../assets/icon_Medium_white.svg'
import TwitterWhiteIcon from "../../assets/icon_twitter_white.svg";
import DiscordWhiteIcon from "../../assets/icon_Discord_white.svg";
import TwitterIcon from "../../assets/icon_twitter.svg";
import MediumIcon from "../../assets/icon_Medium.svg";
import DiscordIcon from "../../assets/icon_Discord.svg";
import Logo from '../SVG/ShortLogo';

const Footer = ({
  isDark = false
}) => {

  const items = [
    Object.values(productsData).map((product) => ({
      name: product.shortTitle,
      link: product.pathname
    })),
    [
      {
        name: "FAQ",
        link: "/faq"
      },
      {
        name: "ZK MPC Technologies",
        link: "/technology"
      },
      {
        name: "Roadmap",
        link: "/roadmap"
      },
      {
        name: "Jiritsu Team",
        link: "/team"
      }
    ],
  ]

  return (
    <footer>
      <Box
        sx={{
          bgcolor: isDark ?  'black.main' : "white",
          padding: {
            lg: '60px 160px',
            xs: '30px'
          }, 
        }}
      >
        <Divider sx={{width: '100%', borderColor: isDark ? "white" : "black.main"}}/>
        <Box
          sx={{
            margin: "30px 0 214px 0",
            display: "flex",
            justifyContent: "space-between",
            gap: "30px",
            flexDirection: {
              xs: 'column',
              sm : 'row',
            },
            color: isDark ? "white" : "black.main"
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: "30px",
              flexDirection: "column",
              color: isDark ? "white" : "black.main"
            }}
          >
            <Link to='/'>
              <Typography variant='p2' sx={{ color: isDark ? "white" : "black.main", fontWeight: 600}}>Jiritsu RWA</Typography>
            </Link>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: {
                xs: "30px",
                sm: "40px",
              },
              flexDirection: {
                xs: 'column',
                sm : 'row',
              },
              color: isDark ? "white" : "black.main",
            }}
          >
            {
              items?.map((item, index) => {
                return (
                  <Box key={index}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      color: isDark ? "white" : "black.main",
                      width: "100%",
                      minWidth: {
                        xs: "auto",
                        lg: "240px"
                      },
                      paddingBottom: {
                        xs: "30px",
                        sm: "none",
                      },
                      borderBottom: {
                        xs: isDark ? "1px solid #1F1F1F" : "1px solid #D9D9D9",
                        md: "none",
                      },
                    }}
                  >
                    {
                      item?.map((menu, i) => {
                        return (
                          <Link key={i}
                            to={menu.link}
                            style={{
                              color: isDark ? "white" : "black.main",
                            }}
                          >
                            <Typography variant='p2' sx={{width: "100%", display: "block", color: isDark ? "white" : "black.main", paddingBottom: "10px", fontWeight: 600}}>{menu.name}</Typography>
                          </Link>
                        )
                      })
                    }
                  </Box>
                )
              })
            }
          <Box
            sx={{
              display: "flex",
              gap: "20px",
              alignItems: "start",
              paddingBottom: "30px",
              flexDirection: "column",
              width: "100%",
              borderBottom: {
                xs: isDark ? "1px solid #1F1F1F" : "1px solid #D9D9D9",
                md: "none",
              },
              minWidth: {
                xs: "auto",
                md: "240px"
              }
            }}
          >
            <MUILink
              href="https://twitter.com/Jiritsu_Network"
              target="_blank"
              rel="noopener noreferrer"
            >
             <Box component="img" height="18px" src={isDark? TwitterWhiteIcon : TwitterIcon} />
            </MUILink>
            
            <MUILink
              href="https://medium.com/jiritsu-network"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Box component="img" height="18px" src={isDark? MediumWhiteIcon : MediumIcon} />
            </MUILink>

            <MUILink
              href="https://discord.gg/jiritsu-network"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Box component="img" height="18px" src={isDark? DiscordWhiteIcon : DiscordIcon} />
            </MUILink>
          </Box>
          </Box>
        </Box>
        <Box
          sx={{

            display: 'flex',
            justifyContent: 'center',
            alignItems: "center",
            color: 'white',
            gap: '20px',
            flexDirection: {
              xs: 'column',
              md : 'row',
            },
          }}
        >
          <Box
            sx={{
              display: "flex"
            }}
          >
            <Logo color={ isDark ? "white" : "black.main"} />
          </Box>
          <Typography
            component={'p'}
            variant='p5'
            sx={{
              position: {
                xs: 'relative',
                md : 'absolute',
              },
              left: {
                xs: '0',
                md : '160px',
              },
              color: isDark ? "white" : "black.main"
            }}
          >
            All Rights Reserved. HOPR AI {new Date().getFullYear()}
          </Typography>
        </Box>
      </Box>
    </footer>
  )
}

export default Footer;
