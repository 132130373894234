import React from 'react';

import Hero from '../components/Faq/hero';
import FAQs from '../components/Faq/institutions';
import LearnMore from '../components/Faq/learn-more';
import ScrollToTop from '../components/Scroll/scrollTop';

const FAQInstitutions = () => {
  return (
    <>
      <ScrollToTop/>
      <Hero />
      <FAQs />
      <LearnMore />
    </>
  )
}

export default FAQInstitutions;