import * as React from "react";
import { Typography } from "@mui/material";

const generateCharacter = () =>
  "5IKPRWVN2ZXUEQFDJS46L90OGH73A81CMTBY"[Math.floor(Math.random() * 36)];

const RandomCharacter = ({ interval, char, delay, id }) => {
  const [val, setVal] = React.useState("");
  const [display, setDisplay] = React.useState(false);

  const reset = () => {
    setDisplay(false);
    setVal(generateCharacter());
  };

  React.useEffect(() => {
    reset();
    let cycle = setInterval(() => {
      setVal(generateCharacter());
    }, interval);

    setTimeout(() => {
      clearInterval(cycle);
      setDisplay(true);
    }, delay * 60 /* speed of letters appearing on screen */);
  }, [id, char]);

  return display === false ? (
    <Typography
      component="span"
      variant="h1"
      sx={{
        color: "gray",
        "&:last-child": {
          visibility: "hidden",
        },
      }}
    >
      {val}
    </Typography>
  ) : (
    <Typography
      component="span"
      variant="h1"
      sx={{
        color: "#0A0A0A",
      }}
    >
      {char}
    </Typography>
  );
};

export default RandomCharacter;
