import React, { useState } from 'react'
import { AppBar, Box, Toolbar, Typography, Button, Link as MUILink } from '@mui/material';
import { Link } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Consultation from '../Consultation';
import { ReactComponent as Logo } from '../../assets/logo.svg';
import { getLocalStorage, setLocalStorage } from '../../utils/localstorage';
import DownArrow from '../SVG/DownArrow';
import UpRightArrow from '../SVG/UpRightArrow';
import { productsData } from '../../pages/prospectus';

const Topbar = ({setOpen}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorDocsEl, setAnchorDocsEl] = React.useState(null);
  const openDocs= Boolean(anchorDocsEl);
  const handleClickDocs = (event) => {
    setAnchorDocsEl(event.currentTarget);
  };
  const handleCloseDocs = () => {
    setAnchorDocsEl(null);
  };

  const [anchorAboutEl, setAnchorAboutEl] = React.useState(null);
  const openAbout= Boolean(anchorAboutEl);
  const handleClickAbout = (event) => {
    setAnchorAboutEl(event.currentTarget);
  };
  const handleCloseAbout = () => {
    setAnchorAboutEl(null);
  };

  return (
    <Toolbar
    sx={{
      display: 'flex',
      padding: {
        xs: '20px 30px',
        xl: '20px 160px',
      },
      gap: '20px',
      bgcolor: 'black.main',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderRadius: {
        xs: "10px",
        md: "20px"
      }
    }}
  >
    <Box
      component={'nav'}
      sx={{
        display: {
          sm: 'flex',
          xs: 'none'
        },
        gap: {
          md: '10px',
          sm: '15px'
        },
        alignItems:'center'
      }}
    >
      <Link
        to='/'
        style={{
          color: "white",
          display: "flex",
        }}
      >
        <Logo height={22} width={173} />
      </Link>
      <Link
        to="/"
        style={{
          color: 'white',
          padding: "5px 15px",
          background: "#FFFFFF0D",
          borderRadius: "5px"
        }}
      >
        <Typography variant='p4' component={'p'}>Home</Typography>
      </Link>
      <MUILink
        href="https://dune.com/jiriledger/jiriledger-metrics"
        target="_blank"
        rel="noopener noreferrer"
        underline="none"
        style={{
          color: 'white',
          padding: "5px 15px",
          background: "#FFFFFF0D",
          borderRadius: "5px",
          display: "flex",
          alignItems: "center"
        }}
      >
        <Typography variant='p4' component={'p'} sx={{mr:0.5}}>Dashboard</Typography> <UpRightArrow height="14px" color='white'/>
      </MUILink>

      <Link
        to='/technology'
        style={{
          color: 'white',
          padding: "5px 15px",
          background: "#FFFFFF0D",
          borderRadius: "5px"
        }}
      >
        <Typography variant='p4' component={'p'}>Technology</Typography>
      </Link>
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        endIcon={<DownArrow color={ open ? "black" : "white"} />}
        sx={{
          fontWeight: 600,
          backgroundColor: open ? "white" : "#FFFFFF0D",
          borderRadius: "5px",
          color: open ? "black.main" : "white",
          textTransform: "none"
        }}
      >
        Products
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
          style: {
            padding: "42px 45px"
          }
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          transform: 'translateY(10px)',
        }}
      >
        {
          Object.values(productsData)?.map((prod, index) => {
            return (
              <MenuItem onClick={handleClose} sx={{marginTop: "30px"}} key={index}>
              <Link
                to={prod.pathname}
                style={{
                  display: "flex",
                  alignItems: "center"
                }}
              >
                <Typography variant='h4' component={"h4"} color="black.main" sx={{mr:2}}>{prod.shortTitle}</Typography> <UpRightArrow color='black'/>
              </Link>
              </MenuItem>
            );
          })
        }
        <MenuItem onClick={handleClose} sx={{ marginTop: '30px' }} key="roadmap">
          <Link
            to='/roadmap'
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography variant='h4' component={'h4'} fontFamily={'Rand Medium'} color='black.main' sx={{ mr: 2 }}>
              Roadmap
            </Typography>{' '}
            <UpRightArrow color='black' />
          </Link>
        </MenuItem>
      </Menu>
    </div>

    <div>
      <Button
        id="docs-button"
        aria-controls={openDocs ? 'docs-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={openDocs ? 'true' : undefined}
        onClick={handleClickDocs}
        endIcon={<DownArrow color={ openDocs ? "black" : "white"} />}
        sx={{
          fontWeight: 600,
          backgroundColor: openDocs ? "white" : "#FFFFFF0D",
          borderRadius: "5px",
          color: openDocs ? "black.main" : "white",
          textTransform: "none"
        }}
      >
        Documentation
      </Button>
      <Menu
        id="docs-menu"
        anchorEl={anchorDocsEl}
        open={openDocs}
        onClose={handleCloseDocs}
        MenuListProps={{
          'aria-labelledby': 'docs-button',
          style: {
            padding: "42px 45px"
          }
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          transform: 'translateY(10px)',
        }}
      >
        {
          Object.entries({
            "White Paper": "https://jiritsu.gitbook.io/jiritsu_white_paper_1.0",
            "Node & Validator guide": "https://docs.google.com/document/d/16ImFPuDSP4qV8oJGVoPaUbd34uashOvWeUCHsKlg6ew",
            "Importing & Claiming Data": "https://docs.google.com/document/d/1sWVCpUTdsJUIAfAUsMTPFvmtUnij_GT3s1455XSoAuM",
            "Permissioned Minting":"https://docs.google.com/document/d/1_9duTXHknvoR_k0JcL1rXX_VsQs4KBWLNlm2rqun6HU/edit?usp=sharing",
          }).map(([routeName, routePath]) => 
            <MenuItem onClick={handleCloseDocs} sx={{marginTop: "30px"}} key={routeName}>
              <MUILink
                href={routePath}
                target="_blank"
                rel="noopener noreferrer"
                underline="none"
                style={{
                  display: "flex",
                  alignItems: "center"
                }}
              >
                <Typography variant='h4' component={"h4"} color="black.main" sx={{mr:2}}>{routeName}</Typography> <UpRightArrow color='black'/>
              </MUILink>
            </MenuItem>
          )
        }
      </Menu>
    </div>
    <div>
      <Button
        id="about-menu-button"
        aria-controls={openAbout ? 'about-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={openAbout ? 'true' : undefined}
        onClick={handleClickAbout}
        endIcon={<DownArrow color={ openAbout ? "black" : "white"} />}
        sx={{
          fontWeight: 600,
          backgroundColor: openAbout ? "white" : "#FFFFFF0D",
          borderRadius: "5px",
          color: openAbout ? "black.main" : "white",
          textTransform: "none"
        }}
      >
        About
      </Button>
      <Menu
        id="about-menu"
        anchorEl={anchorAboutEl}
        open={openAbout}
        onClose={handleCloseAbout}
        MenuListProps={{
          'aria-labelledby': 'about-menu-button',
          style: {
            padding: "42px 45px"
          }
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          transform: 'translateY(10px)',
        }}
      >
        {
          Object.entries({
            "/faq": "FAQ",
            "/zkmpc-verifier": "ZKMPC Verifier",
            "/team": "Team",
          }).map(([routePath, routeName]) => 
            <MenuItem onClick={handleCloseAbout} sx={{marginTop: "30px"}} key={routePath}>
              <Link
                to={routePath}
                style={{
                  display: "flex",
                  alignItems: "center"
                }}
              >
                <Typography variant='h4' component={"h4"} color="black.main" sx={{mr:2}}>{routeName}</Typography> <UpRightArrow color='black'/>
              </Link>
            </MenuItem>
          )
        }
      </Menu>
    </div>
    </Box>
    {/* <Box
      sx={{
        display: "flex",
        gap: "10px"
      }}
    >
      <Button
        variant='outlined'
        sx={{
          borderRadius: '30px',
          padding: '15px 20px',
          borderColor: "#FBA850"
        }}
        onClick={() => setOpen(true)}
      >
        Consultation
      </Button>
    </Box> */}
  </Toolbar>
  )
}

const Desktop = ({ isLight }) => {
  const [hide, setHide] = useState(getLocalStorage())
  const [open, setOpen] = useState(false)

  const onClose = () => {
    setHide(true);
    setLocalStorage(true)
  }

  return (
    <>
      <AppBar
        sx={{
          filter: isLight ? "invert(1)" : undefined,
          background: "transparent",
          boxShadow: "none",
          width: "calc(100% - 20px)",
          left: {
            xs: "5px",
            md: "10px"
          },
          top: {
            xs: "5px",
            md: "10px"
          },
          borderRadius: {
            xs: "10px",
            md: "20px"
          }
        }}
      >
        {/* {
          !hide ? (
            <Event onClose={onClose}/>
          ) : null
        } */}
        <Topbar setOpen={setOpen}/>
        {
          open ? (
            <Consultation
              open={open}
              handleClose={() => setOpen(false)}
            />
          ) : null
        }
      </AppBar>
      {/* {
        !hide ? (
          <Event onClose={onClose}/>
        ) : null
      } */}
    </>
  )
}

export default Desktop;
