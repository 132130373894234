import * as React from "react";
import { Link } from 'react-router-dom';

import { ReactComponent as MenuIcon } from "../../assets/MenuIcon.svg";
import { ReactComponent as CloseIcon } from "../../assets/CloseIcon.svg";
import { ReactComponent as Logo } from '../../assets/logo.svg';
import Consultation from "../Consultation";
import { productsData } from "../../pages/prospectus";
import UpRightArrow from "../SVG/UpRightArrow";
import DownArrow from '../SVG/DownArrow';

import {
  AppBar,
  Fade,
  IconButton,
  List,
  ListItemButton,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  Toolbar,
  Typography,
  Link as MUILink
} from "@mui/material";

const Header = ({ isLight }) => {
  const [menu, setMenu] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <AppBar
        sx={{
          filter: isLight ? "invert(1)" : undefined,
          height: menu ? "100%" : "60px",
          /* position: "absolute", */ //toggle this on/off to keep on screen when scrolling
          transition: "height 0.5s",
          transitionTimingFunction: "ease-out",
          bgcolor: 'black.main',
          color: 'white',
          width: menu ? "100%" : "calc(100% - 10px)",
          left: {
            xs: menu ? "0px" : "5px",
            lg: "10px"
          },
          top: {
            xs: menu ? "0px" : "5px",
            lg: "10px"
          },
          borderRadius: {
            xs: "10px",
            lg: "20px"
          }
        }}
      >
        <Toolbar
          disableGutters
          sx={{ flexDirection: "column", backgroundColor: 'black.main',
          borderRadius: {
            xs: "10px",
            lg: "20px"
          },
          height: "100%"
        }}
        >
          <Stack
            direction="row"
            width="100%"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              backgroundColor: 'black.main',
              padding: "16px 30px",
              borderRadius: {
                xs: "10px",
                lg: "20px"
              },
            }}
          >
            <Link
              to='/'
              style={{
                color: "white",
                display: "flex",
              }}
              onClick={() => setMenu(false)}
            >
              <Logo height={22} width={173}/>
            </Link>
            <IconButton
              size="small"
              onClick={() => {
                setMenu(prev => !prev);
              }}
            >
            {menu ? <CloseIcon /> : <MenuIcon />}
            </IconButton>
          </Stack>
          <Fade in={menu}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: '#141414',
              height: "100%",
              width: "100%",
              padding: "60px 35px",
              overflow: "scroll"
            }}
          >
            <Box>

              <MUILink
                href="https://dune.com/jiriledger/jiriledger-metrics"
                target="_blank"
                rel="noopener noreferrer"
                underline="none"
                sx={{
                  color: 'white',
                  display: "flex",
                  alignItems: "center",
                  my: "0.75rem",
                }}
                onClick={() => setMenu(false)}
              >
                <Typography variant='h3' component={'h3'} fontWeight={400} sx={{mr: 2}}>Dashboard</Typography> <UpRightArrow color='white'/>
              </MUILink>

              <Link
                to="/technology"
                style={{
                  display: 'block',
                  color: 'white',
                  marginTop: "0.75rem",
                  marginBottom: "0.75rem",
                }}
                onClick={() => setMenu(false)}
              >
                <Typography variant='h3' component={'h3'} fontWeight={400}>Technology</Typography>
              </Link>

              <Accordion
                disableGutters={true}
                square={true}
                sx={{
                  backgroundColor: "transparent",
                  color: "white",
                  boxShadow: "none",
                }}
              >
                <AccordionSummary expandIcon={<DownArrow />} sx={{ padding: "0" }}>
                  <Typography variant='h3' component={'h3'} fontWeight={400}>Products</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <List
                    disablePadding
                    sx={{
                      width: 1,
                      paddingTop: 0,
                      "& .MuiListItemButton-root": {
                      my: "1.25rem",
                      paddingTop: 0,
                      },
                      "& .MuiListItemText-primary": {
                      fontSize: "1.375rem",
                      lineHeight: "1.75rem",
                      },
                    }}
                  >
                    {
                      Object.values(productsData)?.map((prod, index) => {
                        return (
                          <ListItemButton
                            key={index}
                            disableGutters
                            sx={{
                              paddingBottom: "30px"
                            }}
                          >
                            <Link
                              to={prod.pathname}
                              style={{
                                color: 'white',
                                display: "flex",
                                alignItems: "center"
                              }}
                              onClick={() => setMenu(false)}
                            >
                              <Typography variant='h4' component={"h4"} fontWeight={300} sx={{mr:2}}>{prod.shortTitle}</Typography> <UpRightArrow color='white'/>
                            </Link>
                          </ListItemButton>
                        )
                      })
                    }
                    
                    <ListItemButton
                      disableGutters
                      sx={{
                        paddingBottom: "30px"
                      }}
                    >
                      <Link
                        key="roadmap"
                        to="/roadmap"
                        style={{
                          color: 'white',
                          display: "flex",
                          alignItems: "center"
                        }}
                        onClick={() => setMenu(false)}
                      >
                        <Typography variant='h4' component={"h4"} fontWeight={300} sx={{mr:2}} fontFamily={'Rand Medium'}>Roadmap</Typography> <UpRightArrow color='white'/>
                      </Link>
                    </ListItemButton>
                        
                  </List>
                </AccordionDetails>
              </Accordion>
                    
              <Accordion
                disableGutters={true}
                square={true}
                sx={{
                  backgroundColor: "transparent",
                  color: "white",
                  boxShadow: "none",
                }}
              >
                <AccordionSummary expandIcon={<DownArrow />} sx={{ padding: "0" }}>
                  <Typography variant='h3' component={'h3'} fontWeight={400}>Documentation</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <List
                    disablePadding
                    sx={{
                      width: 1,
                      paddingTop: 0,
                      "& .MuiListItemButton-root": {
                      my: "1.25rem",
                      paddingTop: 0,
                      },
                      "& .MuiListItemText-primary": {
                      fontSize: "1.375rem",
                      lineHeight: "1.75rem",
                      },
                    }}
                  >
                    {
                      Object.entries({
                        "White Paper": "https://jiritsu.gitbook.io/jiritsu_white_paper_1.0",
                        "Node & Validator guide": "https://docs.google.com/document/d/16ImFPuDSP4qV8oJGVoPaUbd34uashOvWeUCHsKlg6ew",
                        "Importing & Claiming Data": "https://docs.google.com/document/d/1sWVCpUTdsJUIAfAUsMTPFvmtUnij_GT3s1455XSoAuM",
                        "Permissioned Minting":"https://docs.google.com/document/d/1_9duTXHknvoR_k0JcL1rXX_VsQs4KBWLNlm2rqun6HU/edit?usp=sharing",
                      }).map(([routeName, routePath]) => 
                        <ListItemButton
                          key={routePath}
                          disableGutters
                          sx={{
                            paddingBottom: "30px"
                          }}
                        >
                          <Link
                            to={routePath}
                            style={{
                              color: 'white',
                              display: "flex",
                              alignItems: "center"
                            }}
                            onClick={() => setMenu(false)}
                          >
                            <Typography variant='h4' component={"h4"} fontWeight={300} sx={{mr:2}}>{routeName}</Typography> <UpRightArrow color='white'/>
                          </Link>
                        </ListItemButton>
                      )
                    }
                  </List>
                </AccordionDetails>
              </Accordion>

              <Accordion
                disableGutters={true}
                square={true}
                sx={{
                  backgroundColor: "transparent",
                  color: "white",
                  boxShadow: "none",
                }}
              >
                <AccordionSummary expandIcon={<DownArrow />} sx={{ padding: "0" }}>
                  <Typography variant='h3' component={'h3'} fontWeight={400}>About</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <List
                    disablePadding
                    sx={{
                      width: 1,
                      paddingTop: 0,
                      "& .MuiListItemButton-root": {
                      my: "1.25rem",
                      paddingTop: 0,
                      },
                      "& .MuiListItemText-primary": {
                      fontSize: "1.375rem",
                      lineHeight: "1.75rem",
                      },
                    }}
                  >
                    {
                      Object.entries({
                        "/faq": "FAQ",
                        "/zkmpc-verifier": "ZKMPC Verifier",
                        "/team": "Team",
                      }).map(([routePath, routeName]) => 
                        <ListItemButton
                          key={routePath}
                          disableGutters
                          sx={{
                            paddingBottom: "30px"
                          }}
                        >
                          <Link
                            to={routePath}
                            style={{
                              color: 'white',
                              display: "flex",
                              alignItems: "center"
                            }}
                            onClick={() => setMenu(false)}
                          >
                            <Typography variant='h4' component={"h4"} fontWeight={300} sx={{mr:2}}>{routeName}</Typography> <UpRightArrow color='white'/>
                          </Link>
                        </ListItemButton>
                      )
                    }
                  </List>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Fade>
        </Toolbar>
        {
          open ? (
            <Consultation
              open={open}
              fullScreen={true}
              handleClose={() => setOpen(false)}
            />
          ) : null
        }
      </AppBar>
    </>
  );
};

export default Header;
