import { Box, Typography } from "@mui/material";
import Accordion from "../Faq/acordion";
import { items } from "./contents";

const FAQs = () => {

  return (
    <Box
    sx={{
      padding: {
        md: "68px 160px",
        xs: "70px 15px",
      },
      backgroundColor: "black.main",
      display: "flex",
      flexDirection: "column",
      gap: {
        xs: "20px",
        md: "30px"
      }
    }}
    >
           {
        items?.map((item) => {
          return (
            <Box key={item.question}>
              <Accordion
                title={item.question}
                description={item.answer}
                checked={item.done}
              />
            </Box>
          )
        })
      }
    </Box>
  )
}

export default FAQs; 
