import * as React from "react";
import ProgressBar from "./ProgressBar";

import Headline from "./Headline.js";
import data from "../../../../assets/technology/HeadlineCarousel/headlineData.json";
// import AltHeadline from './AltHeadline.js';
import { Stack } from "@mui/material";

const { headlines } = data;

const getDuration = string => {
  return 1850 + 60 * (string.length + 12);
};

const timings = (arr => {
  const durations = arr.map(el => getDuration(el));
  let delays = [];
  durations.reduce((sum, curr) => {
    delays.push(sum);
    return sum + curr;
  }, 0);
  return durations.map((el, idx) => {
    return {
      duration: el,
      delay: delays[idx],
    };
  });
})(headlines);

const HeadlineCarousel = () => {
  const [current, setCurrent] = React.useState(0);
  const nextHeadline = () => {
    if (current + 1 < headlines.length) {
      return setCurrent(current => current + 1);
    }
    return setCurrent(0);
  };

  React.useEffect(() => {
    let autoplay = setInterval(nextHeadline, getDuration(headlines[current]));

    return () => {
      clearInterval(autoplay);
    };
  });

  return (
    <Stack
      sx={{
        padding: {
          xs: "120px 30px",
          md: "120px 160px",
        },
        backgroundColor: "#F2F2F2"
      }}
    >
      <Headline text={headlines[current]} />
      {/* <AltHeadline text={headlines[current]}/> */}
      <Stack direction="row" mt={10} spacing={3}>
        {headlines.map((el, idx) => (
          <ProgressBar
            {...timings[idx]}
            currentIdx={current}
            key={idx}
            idx={idx}
          />
        ))}
      </Stack>
    </Stack>
  );
};

export default HeadlineCarousel;
