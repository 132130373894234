import React from 'react';
import { Masonry } from '@mui/lab';
import { Box, Typography, useTheme, useMediaQuery } from '@mui/material';

import MasonryGoldSVG from '../../assets/masonry_gold.svg'
import MasonryMusicSVG from '../../assets/masonry_music.svg'
import MasonryRealEstateSVG from '../../assets/masonry_real_estate.svg'
import { ReactComponent as GoldToken } from '../../assets/gold_token.svg'
import { ReactComponent as MusicToken } from '../../assets/music_token.svg'
import { ReactComponent as PortfolioToken } from '../../assets/portfolio_token_white.svg'

const ImageTile = ({
  image,
  icon
}) => {
  return (
    <Box
      sx={{
        height: {
          xs: 375,
          sm: 720
        },
        backgroundImage : `url(${image})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        padding: {
          xs: '30px',
          sm: '80px 30px',
          lg: '80px'
        }
      }}
    >
      {icon}
    </Box>
  )
}

const MasonrySection = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <Masonry
      columns={{
        xs: 1,
        sm: 2
      }}
      sx={{
        padding: 0,
        margin: 0
      }}
      spacing={0}
    >
      <ImageTile image={MasonryGoldSVG} icon={<GoldToken style={{maxWidth: '100%'}}/>}/>
      <Box
        sx={{
          height: {
            xs: 700,
            sm: 900
          },
          backgroundColor: 'black.light',
          padding: {
            xs: '70px 30px',
            lg: '70px 160px 70px 70px'
          }
        }}
      >
        <Typography variant='h3' mb={{xs:'40px', sm:'100px'}}>
          Gold
        </Typography>
        <Typography variant='h4'>
          Physical gold has been a store of value for centuries.
          An unquenchable demand has made this rare element a universally trusted commodity
          and safe haven, even during economic uncertainties.
          The Jiritsu RWA platform allows you to invest in serialized non-fungible articles of 
          investment grade gold through tokenization, providing you with all the benefits of
          physical gold ownership without the expensive cumbersome necessities of handling and storage.
        </Typography>
      </Box>
      {
        isMobile ? (
          // <ImageTile image={MasonryMusicSVG} icon={<MusicToken style={{maxWidth: '100%'}}/>}/>
          // <ImageTile image={MasonryMusicSVG} icon={<PortfolioToken style={{maxWidth: '100%'}}/>}/>
          <ImageTile image={MasonryMusicSVG} icon={<PortfolioToken style={{maxWidth: '260px', height: '26px'}}/>}/>
        ) : (
          null
        ) 
      }
      <Box
        sx={{
          height: {
            xs: 700,
            sm: 1080
          },
          backgroundColor: 'black.lighter',
          padding: {
            xs: '70px 30px',
            sm: '250px 30px 70px 30px',
            lg: '250px 70px 70px 160px'
          }
        }}
      >
        <Typography variant='h3' mb={{xs:'40px', sm:'100px'}}>
          Tunable Portfolios
        </Typography>
        <Typography variant='h4'>
          Today's legacy technology infrastructure limits wealth management firms' capacity to innovate and achieve greater efficiencies.
          Jiritsu RWA technology, empowers tokenization, and smart contracts and opens up a broader design space,
          enabling firms to construct the next generation of managed solutions,
          including historically hard-to-access alternative investment funds.
        </Typography>
      </Box>
      {
        !isMobile ? (
          <ImageTile image={MasonryMusicSVG} icon={<PortfolioToken style={{maxWidth: '260px', height: '26px'}}/>}/>
        ) : (
          null
        ) 
      }
      {
        isMobile ? (
          <ImageTile image={MasonryRealEstateSVG} icon={<PortfolioToken style={{maxWidth: '260px', height: '26px'}}/>}/>
        ) : (
          null
        ) 
      }
      <Box
        sx={{
          height: {
            xs: 700,
            sm: 900
          },
          backgroundColor: {
            xs: 'black.lighter',
            sm: 'black.dark',
          },
          padding: {
            xs: '70px 30px',
            sm: '250px 30px 70px 30px',
            lg: '255px 160px 70px 70px'
          }
        }}
      >
        <Typography variant='h3' mb={{xs:'40px', sm:'100px'}}>
          Real Estate
        </Typography>
        <Typography variant='h4'>
          Private Equity represent a claim on alternative assets such as real-estate, credit and other RWA .
          The Jiritsu RWA platform allows these to be tokenized, helping investors gain exposure to this market and asset class.
          Tokenizing privates equity unlocks greater liquidity for alternative asset owners by
          enabling them to sell scaled-down pieces of their asset at a premium rate.
        </Typography>
      </Box>
      {
        isMobile ? (
          null
        ) : (
          <ImageTile image={MasonryRealEstateSVG} icon={<PortfolioToken style={{maxWidth: '260px', height: '26px'}}/>}/>
        ) 
      }
    </Masonry>
  )
}

export default MasonrySection;
