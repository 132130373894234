import { Box, Typography } from "@mui/material";

import { ReactComponent as CheckIcon } from "../../assets/Icon_check.svg"
import { useDune } from "../../utils/dune";

const Hero = ({
  title,
  subTitle,
  tavDuneName,
  availability,
  verified,
}) => {
  const duneClient = useDune();

  const TAVEnabled = Boolean(tavDuneName);
  const TAVByRWAQuery = duneClient.useQuery('3758075', { enabled: TAVEnabled });
  const TAV = Number(TAVByRWAQuery.data?.result.rows.find(r => r.verifier_name === tavDuneName)?.total_value) ?? Number.NaN;

  const TAVString = (() => {
    if (!TAVEnabled) return "-";
    else if (TAVByRWAQuery.isLoading) return 'Loading...';
    else if (TAVByRWAQuery.error) return 'N/A';
    else if (Number.isNaN(TAV)) return 'N/A';

    return TAV >= 1_000_000_000 ? `$${(TAV / 1_000_000_000).toFixed(2)}B` : `$${(TAV / 1_000_000).toFixed(2)}M`;
  })()

  return (
    <Box
      sx={{
        backgroundColor: "black.main",
        padding: {
          xs: "260px 30px 250px 30px",
          lg: "260px 160px 50px 160px"
        }

      }}
    >
      <Typography component={'h1'} variant="h1" sx={{fontWeight: 300}}>{title}</Typography>
      <Typography component={'h4'} variant="h4" sx={{fontWeight: 300, marginTop: "60px"}}>{subTitle}</Typography>
      <Box
        sx={{
          marginTop: "10px",
          display: "flex",
          gap: "10px"
        }}
      >
        <Box 
          sx={{
            backgroundColor: "white",
            borderRadius: "4px",
            padding: "15px 20px",
            color: "black.main",
            display: "flex",
            alignItems: "center"
          }}
        >
          <Typography component={'p'} variant="p3" sx={{fontWeight: 600}}>{TAVString} TAV</Typography>
        </Box>
        <Box
          sx={{
            backgroundColor: "#FFFFFF1A",
            borderRadius: "4px",
            padding: "15px 20px",
            color: "white",
            border: "1px solid white",
            display: "flex",
            alignItems: "center",
            gap: "6px"
          }}
        >
          {
            availability.toLowerCase() === 'live'
              ? <CheckIcon />
              : <Box
                  sx={{
                    width: "20px",
                    height: "20px",
                    border: "2px solid #3E54FF",
                    borderRadius: "2px",
                    backgroundColor: "#3E54FF"
                  }}
                />
          }
          <Typography component={'p'} variant="p3" sx={{fontWeight: 600}} textTransform={'none'} fontSize={'18px'}>
            {verified}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default Hero; 
