const storage_id = "tomei_event_banner"

export const setLocalStorage = (data) => {
  localStorage.setItem(storage_id, data)
}

export const getLocalStorage = () => {
  const data = localStorage.getItem(storage_id)
  return data === "true"
}

export const removeLocalStorage = () => {
  localStorage.removeItem(storage_id)
}