import { Box, Typography, Button } from "@mui/material";
import { ReactComponent as CircleRect } from '../../assets/circle-rect.svg'
import { ReactComponent as UpRightArrow } from '../../assets/up_right_arrow.svg'
import { ReactComponent as CardSVG } from '../../assets/card_hero.svg'
import { ReactComponent as CheckIcon } from "../../assets/Icon_check.svg"
import { ReactComponent as RightCircleArrow } from "../../assets/right-circle-arrow.svg"
import { ReactComponent as Solana } from '../../assets/solana-logo.svg'
import { ReactComponent as Avalanche } from '../../assets/avalan.svg'
import CardRightIcon from '../SVG/CardRightIcon';

const Share = ({
  shortTitle,
  longTitle,
  description,
  availability,
  color,
  subTitle
}) => {

  return (
    <Box
      sx={{
        padding: {
         xs: "68px 30px",
         md: "68px 160px", 
        },
        background: "white",
        display: "flex",
        flexDirection: {
          xs: "column-reverse",
          lg: "row"
        },
        gap: "30px",
        justifyContent: "space-between"
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: "640px"
        }}
      >
        <Typography component={'h2'} variant="h2" sx={{color: "black.dark", fontWeight: 400}}>{longTitle}</Typography>
        <Typography component={'p'} variant="h4" sx={{color: "black.dark", marginTop: "60px", fontWeight: 400}}
        >
          {description}
        </Typography>

        {/* <Box
          sx={{
            width: "100%",
            maxWidth: "640px"
          }}
        >
          <Box
            sx={{
              padding: "15px 0",
              borderTop: "1px solid black",
              color: "black.main",
              fontWeight: 600
            }}
          >Trust & Transparency</Box>

          <Box
            sx={{
              padding: "15px 0",
              borderTop: "1px solid black",
              color: "black.main",
              fontWeight: 600,
              display: "flex",
            }}
          >
            <Typography variant="p1" component={'p'} fontWeight={600} sx={{flex: "1 1 0px"}}>Mangement Fees</Typography>
            <Typography variant="p1" component={'p'} fontWeight={600} sx={{flex: "1 1 0px"}}>X.X%</Typography>
          </Box>

          <Box
            sx={{
              padding: "15px 0",
              borderTop: "1px solid black",
              color: "black.main",
              fontWeight: 600,
              display: "flex",
            }}
          >
            <Typography variant="p1" component={'p'} fontWeight={600} sx={{flex: "1 1 0px"}}>Mangement Fees</Typography>
            <Typography variant="p1" component={'p'} fontWeight={600} sx={{flex: "1 1 0px"}}>X.X%</Typography>
          </Box>

        <Box
            sx={{
              padding: "15px 0",
              borderTop: "1px solid black",
              color: "black.main",
              fontWeight: 600,
              display: "flex",
            }}
          >
            <Typography variant="p1" component={'p'} fontWeight={600} sx={{flex: "1 1 0px"}}>Mangement Fees</Typography>
            <Box sx={{flex: "1 1 0px"}}>
              <Typography variant="p1" component={'p'} fontWeight={600}>X.X%</Typography>
              <Typography variant="p1" component={'p'} fontWeight={600}>X.X%</Typography>
            </Box>
          </Box>
        </Box> */}
      </Box>
      <Box
        sx={{
          marginTop: "-250px"
        }}
      >

      <Box
            sx={{
              position: "relative",
              width: {
                xs: "100%",
                md: "500px",
              },
              height: "fit-content"
            }}
          >
            <Box
              sx={{
                backgroundColor: color,
                height: "254px",
                borderRadius: "20px",
                marginTop: "80px",
                width: {
                  xs: "100%",
                  md: "500px",
                },
              }}
            >

            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: "#FFFFFF33",
                backdropFilter:"blur(10px)",
                height: "320px",
                borderRadius: "20px",
                position: "absolute",
                left: "2px",
                bottom: "2px",
                width: {
                  xs: "calc(100% - 4px)",
                  md: "calc(100% - 4px)",
                },
                padding: "15px"
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center"
                }}
              >
                  <CircleRect />
                  <Typography component={'p'} variant='p2' sx={{fontWeight: 300}}>
                    {subTitle}
                  </Typography>
                  <Box sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    zIndex: 0
                  }}>

                  <CardRightIcon color={color} />
                  </Box>
              </Box>
              <Typography component={'h2'} variant='h2' sx={{fontWeight: 300, marginTop: "47px", color: "black.main"}}>{shortTitle}</Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "end",
                  flexGrow: 1
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "15px"
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: "#FFFFFF",
                      borderRadius: "4px",
                      padding: "10px",
                      color: "black.main",
                      display: "flex",
                      alignItems: "center",
                      gap: "6px"
                    }}
                  >
                    {
                      availability.toLowerCase() === 'live'
                        ? <CheckIcon />
                        : <Box
                            sx={{
                              width: "20px",
                              height: "20px",
                              border: "2px solid #3E54FF",
                              borderRadius: "2px",
                              backgroundColor: "#3E54FF"
                            }}
                          />
                    }
                    <Typography component={'p'} variant="p3" sx={{fontWeight: 600}} textTransform={'none'}>
                    {availability}
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ display: "flex", gap: "10px" }}>
                  <Avalanche style={{width: "45px", height: "45px"}} />
                  <Solana style={{width: "45px", height: "45px"}} />
                </Box>
              </Box>
            </Box>
          </Box>
      </Box>

    </Box>
  )
}

export default Share; 
