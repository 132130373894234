import { Box } from "@mui/material";
import Accordion from "../Faq/acordion";
import { items } from "./faq-content";

const FAQs = () => {

  return (
    <Box
      sx={{
        padding: {
          md: "68px 160px",
          xs: "70px 15px",
        },
        backgroundColor: "black.main",
        display: "flex",
        flexDirection: "column",
        gap: {
          xs: "20px",
          md: "30px"
        }
      }}
    >
           {
        items?.map((item, index) => {
          return (
            <Box key={index}>
              <Accordion
                title={item.question}
                description={item.answer}
              />
            </Box>
          )
        })
      }
    </Box>
  )
}

export default FAQs; 
