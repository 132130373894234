import React from 'react'
import { useTheme, useMediaQuery } from '@mui/material';

import Desktop from './desktop';
import Mobile from './mobile';

const Header = ({ isLight }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"))

  return (
    <>
      {
        isMobile ? (
          <Mobile isLight={isLight} />
        ) : (
          <Desktop isLight={isLight} />
        )
      }
    </>
  )
}

export default Header;