import * as React from "react";
import widths from "../../../../assets/technology/HeadlineCarousel/charWidthRatios.json";
import RandomCharacter from "./RandomCharacter.js";

import { Box, Stack, Fade } from "@mui/material";

const Headline = ({ text }) => {
  const getWidth = (word, fontSize) => {
    const values = word.split("").map(el => Math.ceil(widths[el] * fontSize));
    return values.reduce((sum, curr) => sum + curr);
  };

  let startDelay = 12; // delay when headline starts resolving
  const [display, setDisplay] = React.useState(false);

  React.useEffect(() => {
    setDisplay(false);
    setTimeout(() => {
      setDisplay(true);
    }, 250);
  }, [text]);

  return (
    <Box mx="auto" sx={{ overflowY: "hidden" }}>
      <Fade in={display}>
        <Stack direction="row" justifyContent="flex-start" flexWrap="wrap">
          {text.split(" ").map((word, wordIndex) => {
            startDelay += word.length;
            const width = getWidth(word, 50);
            return (
              //right margin emulates a blankspace
              <Stack
                direction="row"
                mr={1}
                key={wordIndex}
                sx={{
                  overflowX: "hidden",
                  overflowY: "hidden",
                  width: `${width}px`,
                  flexWrap: "flex",
                }}
              >
                {word.split("").map((char, index) => {
                  return (
                    <RandomCharacter
                      key={index}
                      id={{ wordIndex, index }}
                      interval={200 + Math.floor(Math.random() * 2) * 40} // speed of number cycling
                      char={char}
                      delay={startDelay + index - word.length}
                    />
                  );
                })}
              </Stack>
            );
          })}
        </Stack>
      </Fade>
    </Box>
  );
};

export default Headline;
