import DesktopView from '../components/TechnologyDesktop';
import MobileView from '../components/TechnologyMobile';
import { useTheme, useMediaQuery } from '@mui/material';
import ScrollToTop from '../components/Scroll/scrollTop';

const Technology = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <>
      <ScrollToTop/>
      {isMobile ? <MobileView/> : <DesktopView/>}
    </>
  )
}

export default Technology;