import React from "react";

const UpRightArrow = ({
  width = 18,
  height = 18,
  color = 'white'
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M13.1248 6.7346C13.1248 5.56697 12.178 4.62012 11.0104 4.62012H3.28151V6.7346H9.5197L0 16.2543L1.49488 17.7498L11.0104 8.23429V14.4683H13.1248V6.7346Z" fill={color}/>
    </svg>
  )
}

export default UpRightArrow;
