import * as React from "react";

import HeadlineCarousel from "./sections/HeadlineCarousel";
import StageList from "./sections/StageList";
import WhyJiri from "./sections/WhyJiri";
import TechnologyList from "./sections/TechnologyList";
import Platform from "./sections/Platform";

import { Stack, Box } from "@mui/material";

const DesktopView = () => {
  return (
    <div className="DesktopView" style={{ overflowX: "hidden" }}>
      <Stack>
        <Box height="80px">
        </Box>
        <HeadlineCarousel />
        <StageList />
        <Platform />
      </Stack>
      <Stack spacing="10px" width="auto">
        <WhyJiri />
        <TechnologyList />
      </Stack>
    </div>
  );
};

export default DesktopView;
