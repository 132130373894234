import React from 'react';
import { Box, Typography, Collapse, Stack } from '@mui/material';

import { ReactComponent as CloseIcon } from '../../assets/faqs_close.svg'
import { ReactComponent as OpenIcon } from '../../assets/faqs_open.svg'
import { ReactComponent as CheckmarkIcon } from "../../assets/Icon_checkmark.svg"

const Accordion = ({title, description, checked}) => {
  const [isOpen, setOpen] = React.useState(false)

  const handleClick = () => {
    setOpen(!isOpen)
  }

  return (
    <Box
      sx={{
        backgroundColor: isOpen ? "white" : "",
        color: isOpen ? "black.main" : "white",
        borderRadius: isOpen ? "30px" : ""
      }}
    >
      <Box
        display={'flex'}
        alignItems={'start'}
        sx={{
          gap: "10px",
          backgroundColor: isOpen ? "white" : "#111814",
          padding: '30px',
          alignItems: "center",
          borderRadius: "30px",
        }}
        onClick={handleClick}
      >
        <Stack width='auto' sx={{
          "&:hover": {
            cursor: "pointer"
          }
        }}>
          {!isOpen ? <CloseIcon style={{transition: "all 0.3s ease"}} /> : <OpenIcon style={{ transition: "all 0.3s ease"}}/>}
        </Stack>
        <Typography fontFamily="Rand Medium" variant='p1' component={'p'}
        >
          {title}
          {checked && <CheckmarkIcon style={{
            fill: "#FBA850",
            height: "1em",
            width: "auto",
            marginLeft: "4px",
          }} />}
        </Typography>

      </Box>
      <Collapse in={isOpen}>
        <Stack spacing='60px' mb='60px'>
          <Typography variant='p1' component={'p'}
            sx={{
              "& li" : {
                fontSize: '16.5px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: '220%', /* 36.3px */
                letterSpacing: '0.165px',
                listStyleType: "decimal"
              },
              padding: "0 30px"
            }}
            dangerouslySetInnerHTML={{
              __html: description
            }}
          >
          </Typography>
        </Stack>
      </Collapse>
    </Box>
  );
}

export default Accordion;