import * as React from "react";
import GitbookButton from "./GitbookButton";
import TechListItem from "./TechListItem";
import { Grid, Typography, Divider, Box } from "@mui/material";

import data from "../../../../assets/technology/techListData.json";
import { ReactComponent as JiriMPCTag } from "../../../../assets/technology/Diagram/Jiri MPC Tag.svg";
import { ReactComponent as MPCNode3 } from "../../../../assets/technology/Diagram/MPC Node 3.svg";

const { content } = data;

const TechnologyList = React.forwardRef((props, ref) => {
  return (
    <Grid
      container
      sx={{
        color: "#0A0A0A",
        padding: {
          xs: "82px 30px",
          md: "82px 160px"
        }
      }}
      id="technology"
      ref={ref}
    >
      <Grid item xs={12}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          paddingTop: "30px",
          borderTop: "1px solid #3E54FF"
        }}
      >
        <Typography fontSize="16px" fontWeight="600" marginBottom={'40px'} color={'black'}>
          Our Technologies
        </Typography>
        <GitbookButton />
      </Grid>
      <Grid item xs={4}>
        <Typography sx={{ whiteSpace: "pre-line", fontSize: "20px", color: "black" }}>
          {`Jiritsu relies on mathematics to ensure both optimal and secure performance.\n
              Our proprietary and patent pending technology includes a composable nodes that can be easy configured to support any orchestration. The nodes are decentralized to ensure fidelity and uptime.`}
        </Typography>
      </Grid>
      <Grid item container xs={8} justifyContent="center">

      </Grid>

      <Box
        sx={{ 
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "30px 60px",
          margin: "80px -60px 60px",
          width: "100%",
          boxSizing: "content-box",
          borderRadius: "30px",
          backgroundColor: "#FAFAFA"
        }}
      >
        <JiriMPCTag />
        <MPCNode3 />
      </Box>

      <Grid item xs>
        {content.map((obj, idx) => (
          <Box key={idx}>
            <Divider />
            <TechListItem {...obj} />
          </Box>
        ))}
      </Grid>
    </Grid>
  );
});
export default TechnologyList;
