import * as React from "react";
import stages from "../../../../assets/technology/StageList/stageListData";

import {
  Grid,
  Typography,
  List,
  ListItemButton,
  ListItemText,
  Box,
} from "@mui/material";

const StageList = () => {
  const [currentStage, setStage] = React.useState(1);
  const handleListClick = (e, idx) => {
    setStage(idx);
  };

  return (
    <Box
      sx={{
        backgroundColor: "#F2F2F2"
      }}
    >
      <Box
        className="stageList"
        sx={{
          padding: {
            xs: "100px 30px",
            md: "100px 160px 120px 160px",
          },
          position: "relative",
          backgroundColor: "white",
          borderRadius: "60px 60px 0 0"
        }}
      >
        <Grid container mx="auto" height={1} width={9 / 10}>
          <Grid
            item
            xs={12}
            height={1 / 4}
            alignSelf="flex-end"
            sx={{ "& .MuiTypography-root": { fontSize: "30px" } }}
          >
            <Typography
              sx={{
                color: "#0A0A0A",
                maxWidth: "700px"
              }}
            >
            The Jiritsu Network is an unlimited verified compute (UVC) using Multi-Party Computation. 
            </Typography>
            <Typography
              sx={{
                color: "#3E54FF"
              }}
            >
              UVC is a programmable decentralized network.
            </Typography>
          </Grid>
          <Grid item xs={12}
            sx={{
              padding: "30px 0 60px 0",
              marginTop: "120px",
              borderTop: "1px solid #3E54FF"
            }}
          >
            <Typography lineHeight="24px" fontSize={"20px"} color={"black"} fontWeight={600}>
              Layers of Web3 Compute
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <List
              sx={{
                p: 0,
                m: 0,
                "&& .Mui-selected": {
                  background: "#FFFFFF",
                },
              }}
            >
              {stages.map((obj, idx) => {
                return (
                  <ListItemButton
                    key={idx}
                    sx={{
                      borderRadius: "4px",
                      background: "rgba(255, 255, 255, 0.5)",
                      p: "11px 0",
                      mb: "15px",
                      fontSize: "20px",
                      color: "black",
                      "& .MuiTypography-root": {
                        fontWeight: 600,
                      },
                      opacity: currentStage === obj.order ? 1 : 0.5
                    }}
                    // selected={currentStage === obj.order}
                    onClick={e => handleListClick(e, obj.order)}
                  >
                    <Box
                      sx={{
                        height: "36px",
                        width: "36px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "#3E54FF",
                        borderRadius: "100%",
                        fontSize: "20px",
                        color: "white",
                        marginRight: "15px"
                      }}
                    >
                      {obj.order}
                    </Box>
                    <ListItemText sx={{ color: "#0A0A0A" }} primary={obj.name} />
                  </ListItemButton>
                );
              })}
            </List>
          </Grid>
          <Grid item xs={7} mt={1}
            sx={{
              backgroundColor: "#3E54FF",
              borderRadius: "30px 0 30px 0",
              padding: "30px",
              color: "white"
            }}
          >
            <Typography fontWeight={600} fontSize="20px">
              {`Step ${currentStage}`}
            </Typography>
            <br />
            <Typography fontSize="20px" maxWidth={"550px"}>{stages[currentStage - 1].description}</Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default StageList;
