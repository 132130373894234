import React from 'react';
import { Box, Typography, Divider } from '@mui/material';

import Accordion from './acordion';
import { items } from './contents';

const FAQsIndividual = () => {
  return (
    <Box
      sx={{
        backgroundColor: 'black.dark',
        padding: {
          xs: "30px",
          lg: "0 160px"
        }
      }}
    >
      <Typography
        variant='p2'
        component={'p'}
        sx={{
          paddingBottom: {
            xs: '80px',
            sm: '140px'
          }
        }}
      >
        For PE and Asset Managers
      </Typography>
      {
        items?.map((item, index) => {
          return (
            <Box key={index}>
              <Divider sx={{borderColor: '#FBA850'}} />
              <Accordion
                title={item.question}
                description={item.answer}
              />
            </Box>
          )
        })
      }
    </Box>
  )
}

export default FAQsIndividual;