import { CssBaseline, ThemeProvider } from '@mui/material';

import { appTheme } from './themes';
import './App.css';
import AppRoutes from './routes';
import Layout from './components/Layout';
import { DuneProvider, createDuneClient } from './utils/dune';

const duneClient = createDuneClient(process.env.REACT_APP_DUNE_API_KEY);

function App() {
  return (
    <DuneProvider value={duneClient}>
      <ThemeProvider theme={appTheme}>
        <CssBaseline />
        <Layout>
          <AppRoutes />
        </Layout>
      </ThemeProvider>
    </DuneProvider>
  );
}

export default App;
