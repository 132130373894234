import * as React from "react";
import { Add, Close } from "@mui/icons-material";
import { Collapse, Typography, IconButton, Stack } from "@mui/material";

const TechListItem = ({ title, description }) => {
  const [isOpen, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!isOpen);
  };

  return (
    <Stack my="1.25rem">
      <Typography
        fontSize="17.5px"
        letterSpacing=".1em"
        fontWeight={600}
        color={'black'}
      >
        {title}
      </Typography>
      <Stack>
        <IconButton
          sx={{
            justifyContent: "flex-start",
            width: "1.125rem",
            height: "1.125rem",
            color: "#3E54FF",
            mt: ".625rem",
            "& svg": { fontSize: "2rem" },
          }}
          edge="start"
          onClick={handleClick}
        >
          {isOpen ? <Close /> : <Add />}
        </IconButton>
        <Collapse in={isOpen}>
          <Stack
            mt="1rem"
            mb="4rem"
            sx={{
              "& .MuiTypography-root": {
                fontSize: "1.1rem",
                whiteSpace: "pre-line",
              },
            }}
          >
            {description.split("\n").map((text, idx) => {
              const inItalics = text.startsWith("-from Wikipedia");
              return (
                <Typography
                  key={idx}
                  sx={inItalics ? { fontStyle: "italic" } : {}}
                >
                  {text}
                </Typography>
              );
            })}
          </Stack>
        </Collapse>
      </Stack>
    </Stack>
  );
};

export default TechListItem;
