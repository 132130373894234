import { Box, Typography } from "@mui/material";

const Hero = () => {

  return (
    <Box
      sx={{
        backgroundColor: "black.main",
        padding: {
          md: "260px 160px 50px 160px",
          xs: "200px 20px 50px 20px"
        }
      }}
    >
      <Typography component={'p'} variant="p2" sx={{fontWeight: 300, marginBottom: "60px"}}>Disclosures</Typography>
      <Typography component={'h1'} variant="h1" sx={{fontWeight: 300}}>Jiritsu Investing Disclosures</Typography>
    </Box>
  )
}

export default Hero; 
