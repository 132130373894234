export const items = [
    {
      question: "What is the Jiritsu blockchain?",
      answer: "The Jiritsu blockchain is a Layer 1 solution designed for tokenizing and managing real-world assets (RWAs) using advanced technologies such as Zero-Knowledge Proofs and Multi-Party Computation (MPC). It ensures secure, private verification of assets and enhances liquidity and accessibility by incorporating regulatory compliance directly into digital tokens."
    },
    {
      question: "How does the tokenization process work on the Jiritsu blockchain?",
      answer: `
      The process involves several steps:
      <ul>
      <li>User registration on the Jiritsu blockchain platform.</li>
      <li>Selection of an asset for tokenization.</li>
      <li>Submission of asset details for verification.</li>
      <li>Analysis and value extraction of the asset by ZK-MPC verifiers.</li>
      <li>Generation and storage of a cryptographic proof on the blockchain.</li>
      <li> Claiming of the cryptographic proof by a tokenizer, embedding it into a smart contract.</li>
      <li>The asset is then represented as a token ready for transactions.</li>
      </ul>
      `
    },
    {
      question: "What are ZK-MPC verifiers?",
      answer: `ZK-MPC verifiers are central to the Jiritsu blockchain's protocol. These are algorithms tasked with analyzing and extracting crucial value-defining features and policy-related stipulations from RWAs. They perform comprehensive assessments including asset valuation and regulatory compliance, all without compromising the confidentiality of sensitive data.`
    },
    {
      question: "What is the role of cryptographic proofs in the Jiritsu blockchain?",
      answer: `Cryptographic proofs are generated to encapsulate verified asset information and compliance policies, enhancing security and confidentiality. These proofs are stored on the Jiritsu blockchain, serving as an immutable ledger that supports permanent and public verifiability of an asset's value and policy adherence without exposing sensitive details.`
    },
    {
      question: "How does the Jiritsu blockchain support economic efficiency and monetization?",
      answer: `The Jiritsu blockchain maintains minimal transaction fees (gas fees) to enhance efficiency.`
    },
    {
      question: "What future trajectory does the Jiritsu blockchain envision?",
      answer: `The Jiritsu blockchain aims to bolster the liquidity and accessibility of RWAs by linking compliance, transparency, and real-world value verification directly to the digital representation of assets. It anticipates setting new standards in asset tokenization and serving as a model for future innovations in managing and transacting valued assets digitally.`
    },
    {
      question: "What is Jiritsu's ZK MPC Cloud, and how has it impacted the financial tech industry?",
      answer: `Jiritsu's ZK MPC Cloud is a cutting-edge technology at the core of the Jiritsu network, responsible for facilitating millions of proofs to date. This showcases its robustness and reliability in handling large-scale, secure computations in the financial tech industry. The platform supports Proof of Workflow (PoWF), allowing for the orchestration and verification of computational workflows across various blockchain layers.`
    },
    {
      question: "What are some of the key features of Jiritsu's underlying technology?",
      answer: `Jiritsu's underlying technology boasts several key features. It supports programmable tokenization, allowing assets to be customized for tokenization to suit various asset types. The technology utilizes advanced cryptographic techniques to enhance the security of tokenized assets. It is capable of managing a diverse range of asset types and sizes, providing scalability. The processes are designed to ensure transparency and compliance with regulatory standards. It also provides tools for integrated asset management, allowing for streamlined management of assets post-tokenization. Additionally, it helps in reducing overhead and operational costs through cost optimization measures.`
    },
    {
      question: "What is the Jiritsu Proof of AI, and how does it impact AI technologies?",
      answer: `Jiritsu Proof of AI is a significant advancement in verifying AI workloads, enhancing the efficiency of markets for AI GPU infrastructure. It certifies the reliability and efficiency of AI operations, ensuring optimal use of GPU resources. This is crucial in industries like healthcare and finance, where AI model training demands high integrity and performance.`
    },
    {
      question: "What impact does Jiritsu's technology have in the financial technology sector?",
      answer: `Jiritsu's technology demonstrates exceptional robustness and reliability. It impacts the financial technology sector by enhancing asset liquidity, promoting blockchain interoperability, and ensuring secure and effective asset management.`
    },
    {
      question: `How does "Jiritsu Proof" transform asset attestation and verification?`,
      answer: `"Jiritsu Proof" is an automated attestation service that extends beyond traditional financial verifications to include a broad spectrum of assets. It uses Jiritsu's proprietary technology to ensure secure, transparent, and broad verification of financial and tangible assets, significantly boosting transparency and trust in business evaluations and financial transactions.`
    },
    {
      question: `Who are the founders of Jiritsu, and what are their backgrounds?`,
      answer: `The founders include Jacob Guedalia (CEO), David Guedalia (Chief Architect), Gene Itkis (Professor of Applied Cryptography at MIT Lincoln Labs), Asher Gottesman (20 years in private equity), and Michael Lustig (ex-Managing Director at BlackRock). Their diverse expertise in physics, computational geography, neural computation, cryptography, and financial management drives Jiritsu's innovative approach.`
    },
    {
      question: `What is Jiritsu's relationship with BlackRock?`,
      answer: `Jiritsu is building support and integration with institutional assets, including BlackRock's BUIDL, to verify and enable RWA based on these assets. While we have former BlackRock executive and GM, Michael Lustig on our Board, we do not have a commercial relationship with BlackRock.`        
    },
    {
      question: `What are Total Assets Verified (TAV)?`,
      answer: `Total Assets Verified as a metric of the economic impact of oracle networks indicates the tremendous impact that Jiritsu has on this market by creating trust, transparency, and accurate valuations. This metric is derived from the fact that the off-chain data provided to smart contracts for tokenizing real-world assets (RWAs) directly influences the value of these assets, so it must have visibility and transparency. The interaction between oracles and smart contracts addresses the critical “trust but verify” principle, ensuring that the information regarding asset values is accurate and trustworthy. Jiritsu’s ZKMPC ensures that the chain of custody for this information can be verified. In the context of a trillion-dollar market, if a tokenization process relies on inaccurate or manipulated data about asset values, any operations based on this data will be flawed, potentially leading to misinformed decisions and financial discrepancies. Therefore, the accurate verification of assets by oracles is essential for maintaining user trust and ensuring the sound execution of financial operations.`        
    },
    {
      question: `What are Total Assets Secured (TAS)?`,
      answer: `Total Assets Secured (TAS) is a subset of Total Assets Verified (TAV) focusing on assets uniquely verified by Jiritsu. TAS highlights the security and assurance provided when Jiritsu directly verifies assets, ensuring their accurate valuation and authenticated chain of custody. For example, a gold reserve verified at a Brinks vault for a gold-backed token exemplifies TAS, with Jiritsu directly confirming the physical presence and quantity of gold. In contrast, a BTC balance in an ETF, verified through external data sources, is an example of TAV. TAS emphasizes enhanced trust and security in asset verification, critical for maintaining integrity and reliability in financial operations and decisions.`        
    }
  ]
