import { createTheme } from '@mui/material/styles';
 
export const appTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    primary: {
      main: '#ffffff'
    },
    secondary: {
      main: '#000000'
    },
    black: {
      main: '#000000',
      dark: '#0A0A0A',
      light: '#141414',
      lighter: '#1F1F1F',
    }
  },
  typography: {
    fontFamily: [
      '"Rand Light"',
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    h1: {
      margin: 0,
      padding:0,
      fontSize: '80px',
      fontWeight: 600,
      lineHeight: '96px',
      letterSpacing: '-0.02em'
    },
    h2: {
      margin: 0,
      padding:0,
      fontSize: '46px',
      fontWeight: 600,
      lineHeight: '59.8px',
      letterSpacing: '0.01em'
    },
    h3: {
      margin: 0,
      padding:0,
      fontSize: '30px',
      fontWeight: 600,
      lineHeight: '39px',
      letterSpacing: '0.01em'
    },
    h4: {
      margin: 0,
      padding:0,
      fontSize: '20px',
      fontWeight: 600,
      lineHeight: '30px',
      letterSpacing: '0.02em'
    },
    p1: {
      fontSize: '17.5px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '150%', /* 26.25px */
      letterSpacing: '0.35px',
    },
    p2: {
      fontFamily: 'Rand Medium',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: '150%', /* 24.75px */
      letterSpacing: '0.33px',
      textTransform: 'capitalize',
    },
    'p2-list': {
      fontSize: '16.5px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '220%', /* 36.3px */
      letterSpacing: '0.165px',
    },
    p3: {
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '600',
      lineHeight: '100%', /* 14px */
      letterSpacing: '0.56px',
      textTransform: 'uppercase',
    },
    p4: {
      fontFamily: 'Rand Medium',
      fontSize: '15px',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: '150%', /* 24px */
      letterSpacing: '0.3px',
      textTransform: 'capitalize',
    },
    p5: {
      fontSize: '11px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '100%', /* 11px */
      letterSpacing: '0.22px',
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
          body {
            color: white;
          }
        `,
    }
  }
});

appTheme.typography.h1 = {
  ...appTheme.typography.h1,
  [appTheme.breakpoints.down('md')]: {
    fontSize: '40px',
    lineHeight: 1.2,
  },
};

appTheme.typography.h2 = {
  ...appTheme.typography.h2,
  [appTheme.breakpoints.down('md')]: {
    fontSize: '30px',
    lineHeight: 1.2,
  },
};

appTheme.typography.h3 = {
  ...appTheme.typography.h3,
  [appTheme.breakpoints.down('md')]: {
    fontSize: '24px',
    lineHeight: 1.2,
  },
};