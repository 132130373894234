import React from 'react'
import { useLocation } from 'react-router-dom';
import Hero from '../components/Prospectus/hero';
import Share from '../components/Prospectus/share';
import ScrollToTop from '../components/Scroll/scrollTop';

// color: "#FBA850" Orange
// color: "#3E54FF" Blue
// color: "#FFE1D7" FT Pink

export const productsData = {
    "/product/yielding-stable-coins": {
      color: "#FFE1D7",
      pathname: "/product/yielding-stable-coins",
      shortTitle: "Institutional Asset",
      subTitle: "Ensuring Reserve Integrity",
      longTitle: "Institutional RWA Assets: Enhancing Verification for Tokenized Funds and ETFs",
      description: <>The Jiritsu Proof revolutionizes the security and reliability of institutional RWA assets, such as tokenized funds and ETFs, by implementing real-time verification of their assets. <br/><br/>By collaborating with premier financial institutions, Jiritsu ensures that each tokenized fund and ETF is fully backed by continuously verified assets. <br/><br/>Our commitment to real-time audits, powered by our ZK MPC Cloud Core Technology, greatly enhances the transparency and trustworthiness of transactions involving these sophisticated financial instruments. <br/><br/>This proactive approach provides stakeholders with immediate and precise updates on asset status, setting a new standard in the management and oversight of institutional real-world assets.</>,
      availability: "Live",
      tavDuneName: 'Blackrock BTC ETF',
      verified: "Verified"
    },
    "/product/supply-chain-and-inventory-verification" : {
      color: "#3E54FF",
      pathname: "/product/supply-chain-and-inventory-verification",
      shortTitle: "Supply Chain and Inventory",
      subTitle: "Streamline Loans and Transactions",
      longTitle: "Supply Chain and Inventory Verification: Streamlining Loans and Enhancing Supply Chain Efficiency",
      description: <>The Jiritsu Proof redefines supply chain management by offering real-time verification of inventory, crucial for securing loans and optimizing supply chain operations.<br/><br/> Our technology integrates seamlessly with supply chains, providing continuous proof of inventory levels and conditions. This verification is powered by our ZK MPC Cloud Core Technology, ensuring that inventory data is accurate and tamper-proof.<br/><br/> By facilitating quicker and more reliable inventory assessments, Jiritsu helps businesses secure financing more easily and manage their supply chains more efficiently, leading to reduced costs and improved operational reliability.</>,
      availability: "Live",
      tavDuneName: 'Cumulative Amazon 3P Supply',
      verified: "Verified"
    },
    "/product/verification-of-gold-reserves" : {
      color: "#FBA850",
      pathname: "/product/verification-of-gold-reserves",
      shortTitle: "Verification of Gold Reserves",
      subTitle: "Verifying Reserve in Custodial Safe",
      longTitle: "Verification of Gold Reserves: Setting New Transparency Standards",
      description: <>The Jiritsu Proof revolutionizes the transparency and security of gold investments by facilitating daily verification of gold reserves. <br/><br/>In collaboration with trusted custodians like Brinks, we ensure that the physical gold backing each tokenized asset is verified daily, advancing beyond traditional quarterly audits. <br/><br/>Our ZK MPC Cloud Core Technology underpins this rigorous validation process, bolstering the reliability and economic efficiency of gold transactions. <br/><br/>Jiritsu's innovative approach offers stakeholders continuous, accurate visibility into their gold investments, leading a transformative shift in asset integrity management within the blockchain landscape.</>,
      availability: "Soon",
      verified: "Soon"
    },
    "/product/real-estate-tokenization" : {
      color: "#FBA850",
      pathname: "/product/real-estate-tokenization",
      shortTitle: "Real Estate Tokenization",
      subTitle: "Verifying Cash-flow and Valuation”",
      longTitle: "Real Estate Tokenization: Enhancing Transparency with Real-Time Asset Verification",
      description: <>The Jiritsu Proof is revolutionizing real estate investments by enabling real-time tokenization and verification of property assets.<br/><br/> Our technology allows for the seamless integration of real-world properties into the blockchain, ensuring that each tokenized asset is backed by a verified real property. <br/><br/>This process is supported by our advanced ZK MPC Cloud Core Technology, which provides continuous, real-time verification, increasing trust and efficiency in real estate transactions.<br/><br/> Jiritsu's innovative approach not only simplifies the investment process but also provides a robust layer of transparency and security, paving the way for a new era in real estate management.</>,
      availability: "Soon",
      verified: "Soon"
    },
    "/product/bank-balance-verification" : {
      color: "#3E54FF",
      pathname: "/product/bank-balance-verification",
      subTitle: "Accelerate and Secure Transactions",
      shortTitle: "Bank Balance",
      longTitle: "Bank Balance Verification: Accelerating Financial Transactions with Real-Time Proof",
      description: <>The Jiritsu Proof is transforming financial services by providing real-time verification of bank balances, crucial for accelerating loan approvals and ACH transactions.<br/><br/> Our technology integrates directly with over 1100 financial institutions, ensuring that the verification process is both seamless and secure.<br/><br/> By utilizing our ZK MPC Cloud Core Technology, Jiritsu offers instantaneous proof of bank balances, which streamlines financial operations, reduces risk, and enhances the speed of credit and payment processes.<br/><br/> This innovative approach ensures that financial institutions can operate with greater efficiency and trust, making swift financial decisions with confidence.</>,
      availability: "Soon",
      verified: "Soon"
    },
    "/product/ownership-and-title-verification" : {
      color: "#FFE1D7",
      pathname: "/product/ownership-and-title-verification",
      shortTitle: "Ownership and Title",
      subTitle: "Securing Collateral and Value",
      longTitle: "Ownership and Title Verification: Securing Collateral with Real-Time Blockchain Proof",
      description: <>The Jiritsu Proof enhances financial security by providing real-time verification of ownership and title, essential for managing collateral and expediting loan processes.<br/><br/> Our blockchain technology ensures that titles and ownership details are accurately reflected and instantly verifiable, making them reliable for use as collateral.<br/><br/> This capability is backed by our ZK MPC Cloud Core Technology, which enables seamless and secure validation of property titles and ownership records.<br/><br/> By reducing the risk and time associated with traditional verification processes, Jiritsu empowers lenders and borrowers with the confidence to make faster and more informed financial decisions.</>,
      availability: "Soon",
      verified: "Soon"
    },
    "/product/tokenizing-gpu-hours" : {
      color: "#FBA850",
      pathname: "/product/tokenizing-gpu-hours",
      shortTitle: "GPU Hours and DePIN",
      subTitle: "Ensuring Accurate Infrastructure Delivery in Real-Time",
      longTitle: "Tokenizing GPU Hours: Ensuring Accurate Asset Delivery in Real-Time",
      description: <>The Jiritsu Proof is pioneering the tokenization of GPU hours, enabling a secure and transparent marketplace for AI hardware utilization.<br/><br/> By verifying each GPU hour as a tokenized asset, our ZK MPC Cloud Core Technology ensures that users receive exactly what they pay for, in real time. This process not only enhances trust but also maximizes efficiency and security in AI hardware transactions.<br/><br/> Jiritsu's innovative approach guarantees that each transaction in the marketplace adheres to the highest standards of integrity, providing a reliable and effective solution for accessing and deploying AI resources.</>,
      availability: "Soon",
      verified: "Soon"
    },
}


const Prospectus = () => {
  const {pathname} = useLocation();
  const product = productsData[pathname] ?? {};

  return (
    <>
      <ScrollToTop key={pathname} />
      <Hero
        title={product.shortTitle}
        subTitle={product.subTitle}
        availability={product.availability}
        tavDuneName={product.tavDuneName}
        verified={product.verified}
      />
      <Share 
      color={product.color} 
      shortTitle={product.shortTitle} 
      longTitle={product.longTitle} 
      description={product.description}
      subTitle={product.subTitle}
      availability={product.availability}
      verified={product.verified}
      />
    </>
  )
}

export default Prospectus;
