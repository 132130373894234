import React from 'react';
import { useLocation } from 'react-router-dom';


import Header from '../Header';
import Footer from '../Footer';

const Layout = ({children}) => {
  const location = useLocation();
  const darkFooterPages = [
    "/disclosures",
    "/about-rwa"
  ]

  const lightHeaderPages = [
    "/technology",
    "/team",
  ]

  return (
    <>
      <Header isLight={lightHeaderPages.includes(location.pathname)} />
      <div>{children}</div>
      <Footer isDark={darkFooterPages.includes(location.pathname)} />
    </>
  )
}

export default Layout;