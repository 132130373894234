import * as React from "react";
import { Grid, Divider } from "@mui/material";
import Founders from "./Founders";
import Members from "./Members";
import data from "../../../assets/team/teamMemberData.json";

const { founders, members } = data;

const Team = React.forwardRef((props, ref) => {
  return (
    <Grid
      container
      sx={{
        gap: "60px",
        padding: {
          xs: "30px",
          md: "82px 160px",
        },
        "& > :first-of-type": {
          paddingTop: 3,
        },
      }}
      ref={ref}
    >
      <Grid container spacing={"60px"}>
        <Founders data={founders} />
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{borderColor: "#3E54FF"}} />
      </Grid>
      <Grid item container xs={12} rowGap={8}>
        <Members data={members} />
      </Grid>
    </Grid>
  );
});

export default Team;
