import { Button } from "@mui/material";
import { ReactComponent as ArrowRightAlt } from "../../../../assets/up_right_arrow.svg";
const GitbookButton = () => {

  const openGitbook = () => {
    window.open('https://jiritsu.gitbook.io/jiritsu_white_paper_1.0/', '_blank', 'noopener,noreferrer');
  };

  return (
    <Button
      endIcon={<ArrowRightAlt />}
      variant="contained"
      onClick={openGitbook}
      sx={{
        padding: "10px 30px",
        height: "56px",
        color: "white",
        borderRadius: "10px",
        backgroundColor: "#3E54FF",
        "&:hover": {
          backgroundColor: "#3E54FF",
        },
        textTransform: "none",
        fontSize: "17.5px",
        fontWeight: 600,
        alignSelf: "center",
      }}
    >
      Gitbook Documentation
    </Button>
  );
};

export default GitbookButton;
