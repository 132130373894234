import React from 'react'

import Hero from '../components/Disclosures/hero';
import FAQs from '../components/Disclosures/faqs';
import Talk from '../components/Disclosures/talk';
import ScrollToTop from '../components/Scroll/scrollTop';


const Disclosures = () => {
  return (
    <>
      <ScrollToTop/>
      <Hero/>
      <FAQs/>
      <Talk/>
    </>
  )
}

export default Disclosures;