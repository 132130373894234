import { Routes, Route } from "react-router-dom"

import Home from "../pages"
import CaseStudies from "../pages/case-studies"
import FAQIndividuals from "../pages/faq_individuals"
import FAQInstitutions from "../pages/faq_institutions"
import Prospectus from "../pages/prospectus"
import Disclosures from "../pages/disclosures"
// import AboutRWA from "../pages/about-rwa"
import FAQ from "../pages/faq"
import ZKMPCVerifier from "../pages/zkmpc-verifier"
import Roadmap from "../pages/roadmap"
import Technology from "../pages/technology"
import Team from "../pages/team"

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={ <Home/> } />
      {/* <Route path="/prospectus" element={ <Prospectus/> } /> */}
      <Route path="/disclosures" element={ <Disclosures/> } />
      <Route path="/faq" element={ <FAQ/> } />
      <Route path="/zkmpc-verifier" element={ <ZKMPCVerifier/> } />
      <Route path="case-studies" element={ <CaseStudies/> } />
      <Route path="faq_individuals" element={ <FAQIndividuals /> } />
      <Route path="faq_institutions" element={ <FAQInstitutions /> } />
      {/* <Route path="/about-rwa" element={ <AboutRWA /> } /> */}
      <Route path="*" element={ <Home/> } />
      <Route path="/product/:id" element={ <Prospectus/> } />
      <Route path="/roadmap" element={ <Roadmap/> } />
      <Route path="/technology" element={ <Technology/> } />
      <Route path="/team" element={ <Team/> } />
    </Routes>
  )
}

export default AppRoutes