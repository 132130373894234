import * as React from 'react'
import { LinearProgress, Stack } from '@mui/material';

const ProgressBar = ({duration, idx, currentIdx}) => {
  const isCurrent = (currentIdx === idx)
  const isComplete = (idx < currentIdx)
  const [progress, setProgress] = React.useState(isComplete ? 100 : 0);
  
  React.useEffect((() => {
    if (!isComplete) {
      setProgress(0)
    } 
    if (isComplete) {
      setProgress(100)
    }
  }), [isComplete, currentIdx])
  
  React.useEffect(() => {
    
    if (isCurrent) {
      setProgress(0)
      const timer = setInterval((() => {
        setProgress((oldProgress) => {
          if (oldProgress >= 100) {
            clearInterval(timer)
            return 100
          }
          return oldProgress + Math.ceil(100*(100/duration))
        })
      }), 100)
    }

  }, [isCurrent, duration])
  
  const style = {
    width:'60px',
    backgroundColor: '#233D2E',

    '& .MuiLinearProgress-bar1Determinate': {
      backgroundColor: '#3E54FF',
      transition: 'none'
    }
  }

  return (
    <Stack>
      <LinearProgress variant='determinate' sx={style} value={progress}/>
    </Stack>
  )
}

export default ProgressBar