import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
// import HomeHeroSVG from '../../assets/home-hero.svg'
import HomeHeroImage from '../../assets/heor-background.png'
import Consultation from '../Consultation';
import { ReactComponent as CircleRect } from '../../assets/circle-rect.svg'
import { ReactComponent as CardSVG } from '../../assets/card_hero.svg'
import { ReactComponent as CheckIcon } from "../../assets/Icon_check.svg"
import { ReactComponent as RightCircleArrow } from "../../assets/right-circle-arrow.svg"
import { ReactComponent as NextArrow } from "../../assets/next_slider.svg"
import { ReactComponent as PreviewArrow } from "../../assets/prev_slider.svg"
import { ReactComponent as Solana } from '../../assets/solana-logo.svg'
import { ReactComponent as Avalanche } from '../../assets/avalan.svg'
import CardRightIcon from '../SVG/CardRightIcon';
import { productsData } from '../../pages/prospectus';
import { useDune } from '../../utils/dune';

import 'swiper/css';

function formatTAV(value, isLoading, error) {
  if (isLoading) return 'Loading...';
  else if (error) return 'N/A';

  return value >= 1_000_000_000 ? `$${(value / 1_000_000_000).toFixed(2)}B` : `$${(value / 1_000_000).toFixed(2)}M`;
}

const Hero = () => {
  const [open, setOpen] = React.useState(false)
  const navigate = useNavigate();

  const duneClient = useDune();

  const TAVQuery = duneClient.useQuery('3758741');
  const TAV = Number(TAVQuery.data?.result.rows?.[0]?.tav ?? Number.NaN);
  const TAVString = !Number.isNaN(TAV) ? formatTAV(TAV, TAVQuery.isLoading, TAVQuery.error) : 'N/A';

  const TAVByRWAQuery = duneClient.useQuery('3758075');
  const productsWithTAV = Object.keys(productsData).filter(key => productsData[key].tavDuneName);
  const TAVByRWA = Object.fromEntries(productsWithTAV.map(key => {
    const product = productsData[key];
    const TAVValue = TAVByRWAQuery.data?.result.rows.find(r => r.verifier_name === product.tavDuneName)?.total_value ?? Number.NaN;

    return [key, !Number.isNaN(TAVValue) ? formatTAV(TAVValue, TAVByRWAQuery.isLoading, TAVByRWAQuery.error) : 'N/A'];
  }));

  return (
    <Box
      sx={{
        backgroundImage : `url(${HomeHeroImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundColor: 'black.light',
        position: "relative"
      }}
    >
      <Box
        sx={{
          position: "relative",
          padding: {
            xs: '200px 40px 60px 40px',
            lg : '260px 160px 60px 160px',
          },
          "& svg" : {
            width: '100%',
            height: 'auto',
            maxWidth: '700px'
          }
        }}
      >
        <Typography component={'h1'} variant='h1' sx={{ maxWidth: "900px", fontWeight: 300, marginBottom: "60px"}}>
        RWA Verified On-Chain
        </Typography>
        <Typography component={'h3'} variant='h3' sx={{fontWeight: 300}}>Jiritsu is a cutting-edge Layer 1 blockchain with a native ZK-MPC oracle designed for real-world assets</Typography>
      </Box>

      <Box
       sx={{
        padding: {
          //lg: "30px 160px 150px 160px",
          //md: "30px 30px 150px 30px",
          lg: "30px 0 150px 160px",
          md: "30px 0 150px 30px",
          xs: "30px 0 230px 0"
        }
       }}
      >
        <Swiper
          className="hero-slider"
          modules={[Navigation]}
          centeredSlides
          slidesPerView={1}
          loop
          breakpoints={{
            768: {
              centeredSlides: false,
              spaceBetween: 54,
              slidesPerView: "auto",
            },
          }}
          navigation={{
            prevEl: '.hero-prev-arrow',
            nextEl: '.hero-next-arrow',
          }}
        >
          {
            Object.values(productsData)?.map((prod) =>
              <SwiperSlide key={prod.pathname}>
                <Link
                  to={prod.pathname}
                  style={{
                    display: "block",
                    position: "relative",
                    padding: "2px",
                    width: "fit-content",
                    maxWidth: "100%",
                    boxSizing: "border-box",
                    margin: "0 auto",
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: prod.color,
                      height: "254px",
                      borderRadius: "20px",
                      position: "absolute",
                      zIndex: -1,
                      bottom: "0",
                      left: "0",
                      right: "0",
                    }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      backgroundColor: "#FFFFFF33",
                      backdropFilter:"blur(10px)",
                      height: "320px",
                      borderRadius: "20px",
                      width: "450px",
                      maxWidth: "100%",
                      boxSizing: "border-box",
                      padding: "15px"
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center"
                      }}
                    >
                      <CircleRect />
                      <Typography component={'p'} variant='p2' sx={{fontWeight: 300}}>
                        {prod.subTitle}
                      </Typography>
                      <Box sx={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        zIndex: 0
                      }}>
                        <CardRightIcon color={prod.color} />
                      </Box>
                    </Box>
                    <Typography
                      component={'h2'}
                      variant='h2'
                      sx={{
                        fontWeight: 300, 
                        marginTop: {
                          xs: "62px",
                          sm: "47px"
                        },
                        color: "black.main"
                      }}
                    >
                      {prod.shortTitle}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "end",
                        flexGrow: 1
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "15px"
                        }}
                      >
                        <Box
                          sx={{
                            backgroundColor: "#FFFFFF",
                            borderRadius: "4px",
                            padding: "10px",
                            color: "black.main",
                            display: "flex",
                            alignItems: "center",
                            gap: "6px"
                          }}
                        >
                          {
                            prod.availability.toLowerCase() === 'live'
                              ? <CheckIcon />
                              : <Box
                                  sx={{
                                    width: "20px",
                                    height: "20px",
                                    border: "2px solid #3E54FF",
                                    borderRadius: "2px",
                                    backgroundColor: "#3E54FF"
                                  }}
                                />
                          }
                          <Typography component={'p'} variant="p3" sx={{fontWeight: 600}} textTransform={'none'}>
                          {prod.availability}
                          </Typography>
                        </Box>
                        <Button
                          variant='outlined'
                          sx={{
                            borderRadius: "20px",
                            display: "flex",
                            gap: "10px",
                            border: "1px solid white",
                            color: "black.dark",
                            textTransform: "none",
                            fontWeight: 600
                          }}
                        >
                          <RightCircleArrow />
                          TAV { TAVByRWA[prod.pathname] ? TAVByRWA[prod.pathname] : "-"}
                        </Button>
                      </Box>
                      <Box sx={{ display: "flex", gap: "10px" }}>
                        <Avalanche style={{width: "45px", height: "45px"}} />
                        <Solana style={{width: "45px", height: "45px"}} />
                      </Box>
                    </Box>
                  </Box>
                </Link>
              </SwiperSlide>
            )
          }
          <Box
            sx={{
              display: "flex",
              gap: "20px",
              marginTop: "40px",
            }}
          >
            <button type="button" className="hero-prev-arrow">
              <PreviewArrow />
            </button>

            <button type="button" className="hero-next-arrow">
              <NextArrow />
            </button>
          </Box>
        </Swiper>
      </Box>

      <Box
        sx={{
          padding: {
            xs: "0 30px",
            lg: "0 160px",
          },
          position: "absolute",
          bottom: "-100px",
          paddingBottom: {
            xs: "60px",
            md: "0px"
          },
          width: "100%"
        }}
      >

        <Box
          sx={{
            padding: {
              xs: "30px",
              md: "30px 80px",
            },
            borderRadius: "30px",
            // backgroundColor: "#FFFFFF33",
            backgroundColor: "#808080cc",
            width: "100%"
          }}
        >
          <Typography component={'p'} variant='p1' sx={{fontWeight: 300}}>Live and Verified</Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: {
                xs: "column",
                sm: "row"
              },
              marginTop: "20px",
              justifyContent: "space-between"
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <Typography component={'h2'} variant='h2' sx={{fontWeight: 300, fontSize: {xs: "32px", md: "46px"}}}  >{TAVString}</Typography>
              <Typography component={'h3'} variant='h3' sx={{fontWeight: 500, color: "#FBA850", fontSize: "20px !important"}} >TAV</Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <Typography component={'h2'} variant='h2' sx={{fontWeight: 300, fontSize: {xs: "32px", md: "46px"}}}  >6.5M</Typography>
              <Typography component={'h3'} variant='h3' sx={{fontWeight: 500, color: "#FBA850", fontSize: "20px !important"}} >Proofs</Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <Typography component={'h2'} variant='h2' sx={{fontWeight: 300, fontSize: {xs: "32px", md: "46px"}}}  ></Typography>
              <Typography component={'h3'} variant='h3' sx={{fontWeight: 500, color: "#FBA850", fontSize: "20px !important"}} ></Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      {
        open ? (
          <Consultation
            open={open}
            handleClose={() => setOpen(false)}
          />
        ) : null
      }
    </Box>
  )
}

export default Hero;
