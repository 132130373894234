import * as React from "react";

import HeadlineCarousel from "./sections/HeadlineCarousel";
import StageList from "./sections/StageList";
import WhyJiri from "./sections/WhyJiri";
import TechnologyList from "./sections/TechnologyList";
import Platform from "./sections/Platform";

import { Stack } from "@mui/material";

const MobileView = () => {
  return (
    <div
      className="MobileView"
      style={{
        overflowX: "hidden",
        backgroundColor: "rgba(255,255,255,0)",
        transition: "background-color 500ms",
      }}
    >
      <HeadlineCarousel />
      <Stack
        sx={{
          "& > div:nth-of-type(n)": {
            paddingX: "30px",
            width: "auto",
            mt: "1.875rem",
            mb: "7.5rem",
          },
          "&& > hr": {
            marginLeft: "30px",
          },
        }}
        justifyContent="space-between"
      >
        <StageList />
        <Platform />
        <WhyJiri />
        <TechnologyList />
      </Stack>
    </div>
  );
};

export default MobileView;
