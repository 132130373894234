import * as React from "react";
import { Box, Grid, Typography, Button } from "@mui/material";
import { Link } from 'react-router-dom';
import CallMadeIcon from '@mui/icons-material/CallMade';

import JiriZKMPC from "../../../../assets/technology/Platforms/jiri_zk_mpc.svg"
import JiriAI from "../../../../assets/technology/Platforms/jiri_ai.svg"
import JiriRWA from "../../../../assets/technology/Platforms/jiri_rwa.svg"
import JiriProof from "../../../../assets/technology/Platforms/jiri_proof.svg"

const Platform = React.forwardRef((props, ref) => {

  const items = [
    {
      name: "JiriZKMPC",
      logo: JiriZKMPC,
      link: "/"
    },
    {
      name: "JiriAI",
      logo: JiriAI,
      link: "/"
    },
    {
      name: "JiriRWA",
      logo: JiriRWA,
      link: "/"
    },
    {
      name: "JiriProof",
      logo: JiriProof,
      link: "/"
    },
  ]


  return (
    <Box
      sx={{
        backgroundColor: "white"
      }}
      ref={ref}
    >
      <Box
        sx={{
          padding: {
            xs: "80px 30px",
            md: "80px 160px",
          },
          backgroundColor: "#F2F2F2",
          borderRadius: "60px 60px 0 0"
        }}
      >
        <Typography sx={{ color: "#0A0A0A" }} component={"h3"} fontSize={"30px"}>
          Platforms
        </Typography>
        <Grid
          container
          sx={{
            marginTop: "60px"
          }}

        >
          {
            items?.map((item, index) => {
              return (
                <Grid key={index} item xs={12} sm={6} lg={3}>
                    <Box component="img" src={item.logo} alt={item.name}/>
                </Grid>
              )
            })
          }
        </Grid>
        <Box
          sx={{
            width: "100%",
            justifyContent: "center",
            display: "flex"
          }}
        >
          <Button
            component={Link}
            to="/faq"
            endIcon={<CallMadeIcon />}
            variant="contained"
            sx={{
              marginTop: "60px",
              padding: "10px 30px",
              height: "56px",
              color: "white",
              borderRadius: "10px",
              backgroundColor: "#3E54FF",
              "&:hover": {
                backgroundColor: "#3E54FF",
              },
              textTransform: "none",
              fontSize: "17.5px",
              fontWeight: 500,
              alignSelf: "center",
            }}
          >
            Building with jiritsu
          </Button>
        </Box>
      </Box>
    </Box>
  )
});

export default Platform;
