import React from 'react';

import Hero from '../components/CaseStudies/hero';
import MasonrySection from '../components/CaseStudies/masonry';
import LearnMore from '../components/CaseStudies/learn-more';
import ScrollToTop from '../components/Scroll/scrollTop';

const CaseStudies = () => {
  return (
    <>
      <ScrollToTop/>
      <Hero />
      <MasonrySection />
      <LearnMore />
    </>
  )
}

export default CaseStudies;