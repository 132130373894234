import { productsData } from "../../pages/prospectus"

export const items = [
    {
      question: "Deploying ZK Nodes",
      answer: "Implementing Zero-Knowledge nodes to enhance the platform's security and scalability.",
      done: true,
    },
    {
      question: "Testnet",
      answer: `Launching a testnet to validate and refine the network's functionality before a broader rollout.`,
      done: true,
    },
    {
      question: "Audits",
      answer: `Conducting comprehensive audits of the platform to ensure its security, functionality, and compliance.`,
      done: true,
    },
    {
      question: "Initial TAV",
      answer: `Securing the first tranche of Total Value Locked to establish a solid foundation for the platform.`,
      done: true,
    },
    {
      question: "Mainnet",
      answer: `Releasing the mainnet to make the platform accessible to all users and applications.`,
      done: true,
    },
    {
      question: "Continued Integrations for RWA Use Cases",
      answer: `Creating tools and connections for Real World Asset use cases, broadening the platform's applications. ${Object.values(productsData).map(product => product.shortTitle).join(", ")}`
    },
    {
      question: "Additional Decentralization of Validators",
      answer: `Adding additional validator roles to extend the network's robustness and reliability.`
    },
    {
      question: "TGE (Token Generation Event)",
      answer: `Launching the platform's native token.`
    },
    {
      question: "Blockchain support including Avalanche, Solana, Base, and BNB Chain",
      answer: `Establishing native dApps to enable easier integration with key blockchain tokenization.`
    },
    {
      question: `Further decentralization of ZK Nodes`,
      answer: `Adding additional Zero-Knowledge node operators to further increase the platform's resilience and security.`
    },
    {
      question: `Permissionless ZK Nodes`,
      answer: `Allowing open participation in the Zero-Knowledge node network, enhancing decentralization and inclusivity.`
    },
    {
      question: `Adding Additional TAV`,
      answer: `Expanding Total Value Locked through strategic partnerships and platform growth.`
    },
  ]
