import { Box, Typography, Button } from "@mui/material";

const Hero = () => {

  const items = [
    {
      name: "Jiri Gold Token",
      link: "/"
    },
    {
      name: "Jiri Stable Coin",
      link: "/"
    },
    {
      name: "Jiri PE Token",
      link: "/"
    },
    {
      name: "Jiri Real Estate Token",
      link: "/"
    },
    {
      name: "Request A Consultation",
      link: "/"
    },
  ]

  return (
    <Box
      sx={{
        backgroundColor: "black.main",
        padding: {
          lg: '160px',
          sm: '160px 30px',
          xs: '100px 30px'
        }

      }}
    >
      <Typography component={'h3'} variant="h3" sx={{fontWeight: 300, marginBottom: "60px"}}>Let’s talk about how RWA can amplify your investing situation</Typography>
      <Box
        sx={{
          display: "flex",
          gap: "20px",
          marginTop: "60px",
          flexWrap: "wrap",
          flexDirection: {
            xs: "column",
            sm: "row"
          }
        }}
      >
        {
          items?.map((item,index) => {
            return (
              <Button
                variant='outlined'
                key={index}
                sx={{
                  borderRadius: '30px',
                  padding: '10px 15px',
                  borderColor: "#FBA850",
                  width: "fit-content"
                }}
              >
                {item.name}
              </Button>
            )
          })
        }
      </Box>
    </Box>
  )
}

export default Hero; 
