import React from 'react';

const DuneContext = React.createContext();

export const DuneProvider = DuneContext.Provider;
export const useDune = () => React.useContext(DuneContext);

export const createDuneClient = (apiKey) => ({
  useQuery(queryId, { enabled = true } = {}) {
    const [data, setData] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(false);
    const [error, setError] = React.useState(null);

    React.useEffect(() => {
      if (!enabled) return;

      const options = { method: 'GET', headers: { 'X-DUNE-API-KEY': apiKey } };
      setIsLoading(true);

      fetch(`https://api.dune.com/api/v1/query/${queryId}/results`, options)
        .then((response) => {
          if (response.ok) {
          return response.json();
          } else {
          throw new Error('Request failed');
          }
        })
        .then((response) => {
          setData(response);
          setIsLoading(false);
        })
        .catch((err) => {
          setError(err);
          setIsLoading(false);
        });
    }, [queryId, enabled]);

    return { data, isLoading, error };
  },
});
    