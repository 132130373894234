import React from 'react'
import { Box, Typography, Grid, Link as MUILink } from '@mui/material';
import { Link } from 'react-router-dom';

import { ReactComponent as ZKMPC } from '../../assets/jiri_zk_mpc.svg'
import { ReactComponent as AI } from '../../assets/jiri_ai.svg'
import { ReactComponent as Proof } from '../../assets/jiri_proof.svg'
import { ReactComponent as RWA } from '../../assets/jiri_rwa.svg'
import { ReactComponent as IconLink } from '../../assets/icon_link.svg'

const Technologies = () => {
  const items = [
    {
      logo: <ZKMPC />,
      name: "ZKMPC",
      link: "/"
    },
    {
      logo: <AI/>,
      name: "AI",
      link: "/"
    },
    {
      logo: <RWA/>,
      name: "RWA",
      link: "/"
    },
    {
      logo: <Proof/>,
      name: "Proof",
      link: "/"
    }
  ]

  return (
    <Box
      sx={{
        backgroundColor: 'black.main',
        padding: {
          xs: "30px",
          lg: '60px 160px 120px 160px'
        }
      }}
    >
      <Typography component={'h3'} variant='h3' sx={{maxWidth: "793px", color: "white", marginBottom: "30px", fontWeight: 300}}>Enabled by proprietary blockchain technologies</Typography>
      <MUILink
        component={Link}
        to="/technology"
        underline="none"
        variant='p1'
        sx={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
          color: "white",
        }}
      >
        <IconLink />
        Learn more about Jiritsu Technologies
      </MUILink>
      <Grid
        container
        sx={{
          margin: {
            xs: "30px 0",
            sm: '30px 0 120px 0'
          },
          alignItems: "center",
          justifyContent: "space-between"
        }}
      >
        {
          items?.map((item, index) => {
            return (
              <Grid key={index}
                item xs={12}
                sm={6}
                lg={3}
                sx={{
                  width: "100%",
                  gap: "10px",
                  color: "black.main",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: {
                    xs: "center",
                    md: "start"
                  },
                  textAlign: "center"
                }}
              >
                {item.logo}
              </Grid>
            )
          })
        }
      </Grid>
        
    </Box>
  )
}

export default Technologies;
