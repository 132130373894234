
import { Box, Typography } from "@mui/material";
import MembersSection from "../components/Team/Members";
import ScrollToTop from '../components/Scroll/scrollTop';

const Team = () => {
  return (
    <>
      <ScrollToTop/>
      <Box sx={{color: "#0A0A0A"}}>
        <Box
          sx={{
            padding: {
              xs: "180px 30px 80px 30px",
              md: "240px 160px 80px 160px"
            }
          }}
        >
          <Typography component={"h2"} variant="h2"
            sx={{
              textAlign: {
                xs: "center",
                sm: "left"
              }
            }}
          >Our Team</Typography>
        </Box>
        <MembersSection/>
      </Box>
    </>
  )
}

export default Team;