import React from "react";

const DownArrow = ({
  width = 20,
  height = 20,
  color = 'white'
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8.19727 14.6772L0.726682 7.20666L1.78734 6.146C2.17767 5.75568 2.81123 5.75568 3.20156 6.146L9.96503 12.9095L8.19727 14.6772Z" fill={color}/>
      <path d="M9.96484 12.9097L16.7386 6.13594C17.1289 5.74561 17.7625 5.74562 18.1528 6.13594L19.2134 7.1966L11.7326 14.6774L9.96484 12.9097Z" fill={color}/>
    </svg>
  )
}

export default DownArrow;
