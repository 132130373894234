import Typography from "@mui/material/Typography";

import { Divider, Stack, Grid } from "@mui/material";

const Founders = ({ data }) => {
  return data.map(({ name, bio, title }, idx) => (
    <Grid item xs={12} md={6} key={idx}>
      <Stack justifyContent="space-between" spacing={4}>
        <Typography fontSize="20px" color={"black"}>
          {name}&nbsp; &nbsp;
        </Typography>
        <Divider sx={{borderColor: "#3E54FF"}} />
        <Typography fontSize="16px" fontWeight="600" color={"black"}>
          Founder
        </Typography>
        <Typography fontSize="17.5px" color={"black"}>{bio}</Typography>
      </Stack>
    </Grid>
  ));
};

export default Founders;
