import { ReactComponent as IconOne } from "./icon_Top1.svg";
import { ReactComponent as IconTwo } from "./icon_Top2.svg";
import { ReactComponent as IconThree } from "./icon_Top3.svg";
import { ReactComponent as IconFour } from "./icon_Top4.svg";

const data = [
  {
    order: 1,
    name: "Layer 1 Blockchains",
    description:
      "Powered by Virtual machines that are relatively easy to program but have very limited compute power.",
    icon: <IconOne />,
  },
  {
    order: 2,
    name: "Layer 2 Circuits",
    description:
      "ZK Proofs and Starks are complex to program but offer acceleration for specific tasks.",
    icon: <IconTwo />,
  },
  {
    order: 3,
    name: "Middleware Protocols",
    description:
      "Oracles, Keepers, Data verification and AI engines all provide components.",
    icon: <IconThree />,
  },
  {
    order: 4,
    name: "Jiritsu UVC",
    description:
      "MPC Nodes that verify any computation and provide security via Zero Knowledge.",
    icon: <IconFour />,
  },
];

export default data;
