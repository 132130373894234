import * as React from 'react'
import { Collapse, Typography, Box, Stack } from '@mui/material';
import { ReactComponent as IconCross } from "../../../../assets/cross.svg";

const TechListItem = ({title, description}) => {
  const [isOpen, setOpen] = React.useState(false)

  const handleClick = () => {
    setOpen(!isOpen)
  }

  return (
    <Stack direction='row' minHeight='64px'>
      <Typography minWidth='250px' fontSize='17.5px' lineHeight='1.5rem' mt='1.2rem' fontWeight={600} color={"black"}>
        {title}
      </Typography>
      <Stack mt='1.5rem' width='auto'>
        <Box sx={{ cursor: "pointer" }} onClick={handleClick} >
          <IconCross
            style={{
              color: "#3E54FF",
              width: "18px",
              height: "18px",
              rotate: isOpen ? "45deg" : "0deg",
              transition: "all 0.3s ease",
            }}
          />
        </Box>
        <Collapse in={isOpen}>
          <Stack mt='1rem' mb='4rem' pl='.5rem'
          sx={{
            '& .MuiTypography-root': {
              fontSize:'17.5px', 
              whiteSpace:'pre-line', 
            }
            }}>
            {description.split('\n').map((text, idx) => {
              const inItalics = text.startsWith('-from Wikipedia')
              return (
                <Typography key={idx} sx={inItalics ? {fontStyle:'italic'} : {}}>
                  {text}
                </Typography>
              )
            })}
          </Stack>
        </Collapse>
      </Stack>
    </Stack>
  );
}

export default TechListItem